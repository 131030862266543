import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import Logo from "../../assets/img/logo.png";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";



function Header(props) {
  let [menuOpenedClass, setMenuOpenedClass] = useState();
  const selectedStyling = {
    border: "2px solid #4E80BC",
    padding: "5px",
    borderRadius: "5px",
    color: 'rgb(23, 65, 83)'
    // height: "30%",
    // marginTop: "40%"
  };
  const defaultStyling = {
    padding: "7px",
  };
  const selectedNavStyle = {
    kyc: props.selectedNav === "kyc" ? selectedStyling : defaultStyling,

  };
  return (
    <header className={`header ${menuOpenedClass}`}>
      <nav
        className="navbar navbar-expand-lg header-nav"
        style={{ width: "100%" }}
      >
        <div className="navbar-header">
          <a
            id="mobile_btn"
            href="/"
            style={{ color: "rgb(23, 65, 83)" }}
            onClick={(e) => {
              e.preventDefault();
              setMenuOpenedClass("menu-opened");
            }}
          >
            <span className="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>

          <Link style={{ color: 'rgb(23, 65, 83)' }} to="/" className="navbar-brand logo">
            <img src={Logo} alt="Logo" width="100" height="60" />
          </Link>
        </div>

        <div className="main-menu-wrapper">
          <div className="menu-header">
            <a style={{ color: 'rgb(23, 65, 83)' }} href="/" className="menu-logo">
              <img src={Logo} alt="Logo" width="100" height="60" />
            </a>
            <a
              id="menu_close"
              className="menu-close"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setMenuOpenedClass("");
              }}
            >
              <i className="fas fa-times"></i>
            </a>
          </div>
          <ul
            className="main-nav"
            style={{
              marginTop: "4px",
            }}
          >
            <li className="login-link ">
              <Link to="/"
                className="nav-link btn"
                style={{ borderRadius: "5px", backgroundColor: "rgb(23, 65, 83)" }}
              >HOME</Link>
            </li>
            <li className="login-link ">
              <Link to="/login"
                className="nav-link btn"
                style={{ borderRadius: "5px", backgroundColor: "rgb(23, 65, 83)" }}
              >LOGIN / SIGNUP</Link>
            </li>
          </ul>
        </div>
        <ul className="nav header-navbar-rht">
          <li className="nav-item">
            <Link
              to="/login"
              // className="nav-link header-login"
              className="nav-link btn"
              style={{ borderRadius: "5px", backgroundColor: "rgb(23, 65, 83)" }}
            >
              LOGIN / SIGNUP
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
