import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

function HomeBanner() {

  const [width, setWidth] = useState();
  const handleResize = () => {
    // if (window.innerWidth < 720) {
    setWidth(window.innerWidth)
    // } else {
    //     setIsMobile(false)
    // }
  }


  useEffect(() => {
    console.log("window.innerWidth", window.innerWidth);
    setWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)
    // eslint-disable-next-line
  }, []);

  return (
    <section className="align-middle section section-search">
      <div className="align-middle container-fluid">
        <div className="banner-wrapper align-middle" >
          <div className="banner-header  text-center">
            {/* <h1></h1> */}
            <Card style={{ background: ' rgba(23, 65, 83, 0.9) ' }}>
              <CardHeader
                style={{ color: 'white' }}
                title="IMEX (beta version)"
              />
              {/* <Container></Container> */}
              <CardContent style={width < 760 ? ({ marginLeft: '1px', marginRight: '5px', marginBottom: '2px' }) : {
                marginLeft: '100px', marginRight: '100px', marginBottom: '50px'
              }}>

                <Typography variant="body2" color="textSecondary" component="p" style={{
                  color: 'white'
                }}>
                  IMEX is a Platform Where Importer and Exporter can place Orders and can finalize a deal by accepting or rejecting all the terms and conditions under the surveillance of Escrow.An escrow is a contractual arrangement in which a third party receives and disburses money or property for the primary transacting parties, with the disbursement dependent on conditions agreed to by the transacting parties.                </Typography>
              </CardContent></Card>
          </div>
        </div>
      </div>
    </section >
  );
}

export default HomeBanner;
