import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import Footer from "../../../components/Footers/Footer";
import Header from "../../../components/Headers/Header";
import HomeBanner from "./Home/HomeBanner";

function HomeScreen() {
  return (
    <>
      <div className="main-wrapper">
        <div className="home-section home-full-height">
          <Header selectedNav={"home"} />
          <div
            className="content"
            style={{ paddingTop: "100px" }}
            position="absolute"
          >
            <HomeBanner />
          </div>
        </div>
        <Footer position={"relative"} />
      </div>
    </>
  );
}

export default HomeScreen;
