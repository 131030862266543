import {
  Card, Typography
} from '@material-ui/core/';
import axios from "axios";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";


function PendingUserAccountScreen(props) {
  const { enqueueSnackbar } = useSnackbar();
  let [data, setData] = useState([]);
  let getPendingRequests = () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get(
      "Authorization"
    )}`;
    axios
      .get(`/api/v1/admin/pendingAccounts`)
      .then((response) => {
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPendingRequests();
    props.setActiveTab({
      dashboard: "",
      totalUserAccount: "",
      pendingUserAccount: "active",
      nonKYCAccount: "",
      disputedOrders: "",
      tradeListOrders: "",
      earningsList: "",
      referralEarnings: "",
      resolvedDisputedOrders: "",
      settings: "",
      changePassword: "",
    });// eslint-disable-next-line
  }, []);

  let [isSaving, setIsSaving] = useState(false);
  let accountDecision = (id, decision) => {

    let jwt = Cookies.get("Authorization");
    let jwtDecoded = jwtDecode(jwt);
    let adminId = jwtDecoded.id;
    setIsSaving(true);
    let data = {
      status: decision,
    };
    axios
      .put(`/api/v1/admin/account/${id}/${adminId}`, data)
      .then((response) => {
        getPendingRequests();
        if (decision === 'approved') {
          let variant = "success";
          enqueueSnackbar('User Approved Successfully.', { variant });

        } else {
          let variant = "success";
          enqueueSnackbar('User Rejected Successfully.', { variant });
        }
        setIsSaving(false);
      })
      .catch((error) => {
        setIsSaving(false);
        if (decision === 'approved') {
          let variant = "error";
          enqueueSnackbar('Unable to Approve User.', { variant });

        } else {
          let variant = "error";
          enqueueSnackbar('Unable to Reject User.', { variant });
        }
        console.log(error);
        console.log(error.response);
      });
  };

  return (
    <div className="card">
      <ul className="breadcrumb" style={{ backgroundColor: "#174153" }}>
        <li className="breadcrumb-item">
          <a href="/">Dashboard</a>
        </li>
        <li className="breadcrumb-item active">Pending User Accounts</li>
      </ul>

      <div className="card-body">
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="table-responsive" style={{ paddingTop: "40px", paddingBottom: "50px" }}>
                {data.length === 0 ? (
                  <Card variant="outlined" className="text-center" style={{ padding: "40px", marginTop: '20px', marginBottom: '20px' }}>
                    <Typography variant="body2" className="text-center" color="textSecondary" component="p"  >
                      <strong>No items to display </strong>
                    </Typography>
                  </Card>
                ) : (
                  <table className="table table-hover table-center mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>City</th>
                        <th>Country</th>
                        <th>Address</th>
                        <th>Passport Picture</th>
                        <th>Selfie With Passport</th>
                        <th>Business Name</th>
                        <th>Business Certificate Photo</th>
                        <th>Other Pictures</th>
                        <th>Roles</th>
                        <th style={{ textAlign: 'center' }} >Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((i, index) => (
                        <tr key={i._id}>
                          <td>{index + 1}</td>
                          <td>{i.name}</td>
                          {i.emailConfirmed ? (
                            <td>
                              <i
                                className="fa fa-check-circle verified"
                                aria-hidden="true"
                              ></i>{" "}
                              {i.email}
                            </td>
                          ) : (
                            <td>{i.email}</td>
                          )}
                          <td>{i.mobile}</td>
                          <td>{i.city}</td>
                          <td>{i.country}</td>
                          <td>{i.address}</td>
                          <td style={{ textAlign: 'center', alignContent: 'center' }}>
                            <a href={i.passportPicture}
                              download
                              target='_blank'
                              rel="noopener noreferrer"
                            >
                              <img
                                src={i.passportPicture}
                                alt="passport" className="zoom" width={50} height={50}></img>
                            </a>
                          </td>
                          <td style={{ textAlign: 'center', alignContent: 'center' }}>
                            <a href={i.passportsSelfie}
                              download
                              target='_blank'
                              rel="noopener noreferrer"
                            >
                              <img
                                src={i.passportsSelfie}
                                alt="Passport selfie" className="zoom" width={50} height={50}></img>
                            </a>

                          </td>
                          <td>{i.factories}</td>
                          <td style={{ textAlign: 'center', alignContent: 'center' }}>
                            <a href={i.businessCertificate}
                              download
                              target='_blank'
                              rel="noopener noreferrer"
                            >
                              <img
                                src={i.businessCertificate}
                                alt="Business Certificate" className="zoom" width={50} height={50}></img>
                            </a>


                          </td>
                          <td style={{ textAlign: 'center', alignContent: 'center' }}>
                            {i.picturesOfProducts.map((j, jIndex) => (
                              <a key={jIndex} href={j}
                                download
                                target='_blank'
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={j}
                                  alt="products" style={{ marginRight: '10px' }} className="zoom" width={50} height={50}></img>
                              </a>
                            ))}

                          </td>
                          <td>
                            {i.roles.map((role, index) => (
                              <p key={index}>{role}</p>
                            ))}
                          </td>
                          <td>
                            {isSaving ? (
                              <Spinner
                                animation="border"
                                role="status"
                                style={{ color: "#00d0f1" }}
                              >
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            ) : (
                              <>
                                <a
                                  href="home.html"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    accountDecision(i._id, "approved");
                                  }}
                                  className="btn btn-sm bg-success-light"
                                >
                                  <i className="fe fe-trash"></i> Approve
                                </a>
                                <a
                                  href="home.html"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    accountDecision(i._id, "rejected");
                                  }}
                                  className="btn btn-sm bg-danger-light"
                                >
                                  <i className="fe fe-trash"></i> Reject
                                </a>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingUserAccountScreen;
