import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import windowSize from "react-window-size";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import loginBanner from "../../../assets/img/importExport.jpg";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import Footer from "../../../components/Footers/Footer";
import Header from "../../../components/Headers/Header";

function AdminLoginScreen(props) {
    let [email, setEmail] = useState();
    let [password, setPassword] = useState();
    let [isLoading, setIsLoading] = useState(false);
    let [msg, setMsg] = useState("");

    let handleSubmitEvent = (event) => {
        setMsg("");
        setIsLoading(true);
        event.preventDefault();
        axios
            .post("/api/v1/auth/user/login", {
                email: email.toLowerCase(),
                password: password,
                roles: ["admin"]
            })
            .then((response) => {
                console.log("response", response);
                if (response.data === "Please verify your email to Login!") {
                    setMsg("Please verify your email to Login!");
                    setIsLoading(false);
                }
                else if (response.data === "Pending, Please wait for KYC verification!") {
                    setMsg("Pending, Please wait for KYC verification!");
                    setIsLoading(false);
                } else if (response.data === "User is rejected from admin!") {
                    setMsg("Admin Rejected your Account.");
                    setIsLoading(false);
                } else {
                    if (response.data.role === "participant") {
                        setMsg("Individual users must login using Mobile Application.");
                    } else {
                        Cookies.set("Authorization", response.data.token, {
                        });
                        setIsLoading(false);
                        window.location.reload();
                    }
                }
            })
            .catch((error) => {
                if (error.response !== undefined) {
                    if (error.response.status === 400) {
                        setMsg("Incorrect email or password entered");
                    } else if (error.response.status === 501) {
                        setMsg("Cannot send 2FA email, try again.");
                    } else {
                        setMsg("Unknown Error Occured, try again.");
                    }
                } else {
                    setMsg("Unknown Error Occured, try again.");
                }
                setIsLoading(false);
            });
    };

    return (
        <div className="account-page">
            <div className="main-wrapper">
                <div className="home-section home-full-height" >
                    <Header />
                    <div className="content" style={{ paddingTop: "180px", height: "100vh" }} position="absolute">
                        <div className="container-fluid">
                            <div className="row" style={{ height: `${props.windowHeight}`, marginRight: "px" }}>
                                <div className="col-md-8 offset-md-2">
                                    <div className="account-content">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="col-md-8 col-lg-7 login-left" style={{ textAlign: "center" }} >
                                                <img
                                                    src={loginBanner}
                                                    className="img-fluid"
                                                    alt="Doccure Login"
                                                    style={{ height: "400px", paddingTop: "20px" }}
                                                />
                                            </div>
                                            <div className="col-md-11 col-lg-5 login-right">
                                                <div className="login-header">
                                                    <h3 style={{ textAlign: "center" }}>Admin Sign In</h3>
                                                </div>
                                                <form onSubmit={handleSubmitEvent}>
                                                    <div className="form-group form-focus focused">
                                                        <input
                                                            type="email"
                                                            required
                                                            className="form-control floating"
                                                            defaultValue={email}
                                                            value={email}
                                                            onChange={(e) => {
                                                                setEmail(e.target.value);
                                                            }}
                                                        />
                                                        <label className="focus-label">Email</label>
                                                    </div>
                                                    <div className="form-group form-focus focused">
                                                        <input
                                                            type="password"
                                                            required
                                                            className="form-control floating"
                                                            defaultValue={password}
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                        <label className="focus-label">Password</label>
                                                    </div>
                                                    <div className="text-center">
                                                        <p style={{ color: "red" }}>{msg}</p>
                                                    </div>
                                                    <div className="text-right">
                                                        <Link
                                                            to="/forgotPassword"
                                                            className="forgot-link"
                                                            style={{ color: "#000" }}
                                                        >
                                                            Forgot Password ?
                                                        </Link>
                                                    </div>

                                                    {isLoading ? (
                                                        <div className="text-center">
                                                            <Spinner
                                                                animation="border"
                                                                role="status"
                                                                style={{ color: "#00d0f1" }}
                                                            >
                                                                <span className="sr-only">Loading...</span>
                                                            </Spinner>
                                                        </div>
                                                    ) : (
                                                        <button className="btn btn-block btn-lg login-btn" type="submit">
                                                            Sign In
                                                        </button>
                                                    )}
                                                    <div className="text-center dont-have">
                                                        Want to Login as an Importer/Exporter?{" "}
                                                        <Link to="/login">Login</Link>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer position={""} />
            </div>
        </div>
    );

}

export default windowSize(AdminLoginScreen);
