import React, { Component } from 'react';
import PersonalDetails from './PersonalDetails';
import SignupDetails from './SignupDetails';

class MainForm extends Component {
    state = {
        step: 1,
        role: ["importer", "exporter"],
        name: '',
        email: '',
        mobile: '',
        city: '',
        country: '',
        address: '',

    }
    nextStep = () => {
        const { step } = this.state
        this.setState({
            step: step + 1
        })
    }

    prevStep = () => {
        const { step } = this.state
        this.setState({
            step: step - 1
        })
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value })
    }
    handleStepTwo = ({ city, country, address }) => {
        this.setState({
            country, city, address
        });
    }
    handleStepOne = ({ selfiePhoto, businessCertificatePhoto }) => {
        this.setState({ selfiePhoto, businessCertificatePhoto });
    }
    addRole = (role) => {
        this.setState({
            role
        })
    }
    render() {
        const { step } = this.state;
        const { role, name, mobile, email, city, country, address } = this.state;
        const values = { role, name, mobile, email, city, country, address };
        switch (step) {
            case 1:
                return <SignupDetails
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleStepTwo={this.handleStepTwo}
                    values={values}
                />
            case 2:
                return <PersonalDetails
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    values={values}
                />
            default:
        }
    }
}

export default MainForm;