import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import axios from "axios";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from "react";
import Web3 from 'web3';
import Agreement from '../../../../components/blockchain/Abis/Agreement.json';
import Escrow from '../../../../components/blockchain/Abis/Escrow.json';
import USDTToken from '../../../../components/blockchain/Abis/USDTToken.json';
import * as Addresses from '../../../../components/blockchain/Addresses/Addresses';
import AcceptRejectImporterDocumentModal from "../../../../components/Modals/AcceptRejectImporterDocumentModal.js";
import DisputeOrderModal from "../../../../components/Modals/DisputeOrdersModal";
import FinalizeConfirmationModal from "../../../../components/Modals/FinalizeConfirmationModal.js";
import LockingFundsConfirmationModal from "../../../../components/Modals/LockingFundsConfirmationModal.js";
import OrderAcceptModal from "../../../../components/Modals/OrderAcceptModal.js";
import OrderDetailModal from "../../../../components/Modals/OrderDetailModal.js";
import ReceivedandReleaseFundsModal from "../../../../components/Modals/ReceivedandReleaseFundsModal";
import UploadDocumentModal from "../../../../components/Modals/UploadDocumentModal.js";
import CardsTabPanel from '../../../../components/Tabs/CardsReceivedOrdersTabs.js';
import TabPanel from '../../../../components/Tabs/RecievedOrdersTabs.js';
function OrdersReceived(props) {
  const handleClose = () => setShow(false);
  let [show, setShow] = useState(false);
  let [order, setOrder] = useState();
  const [value, setValue] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const handleCloseAcceptModal = () => {
    setShowAcceptModal(false);
    setErrorMsg("");
    setIsSuccess(false);
    setIsError(false);
  }
  const handleShowAcceptModal = () => setShowAcceptModal(true);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const handleCloseUploadModal = () => {
    setShowUploadModal(false);
    setErrorMsg("");
    setIsSuccess(false);
    setIsError(false);
  }
  const handleShowUploadModal = () => setShowUploadModal(true);
  const [showAcceptRejectModal, setShowAcceptRejectModal] = useState(false);
  const handleCloseAcceptRejectModal = () => {
    setShowAcceptRejectModal(false);
    setErrorMsg("");
    setIsSuccess(false);
    setIsError(false);
  }
  const handleShowAcceptRejectModal = () => setShowAcceptRejectModal(true);
  const [showFinalizeModal, setShowFinalizeModal] = useState(false);
  const handleCloseFinalizeModal = () => {
    setShowFinalizeModal(false);
    setErrorMsg("");
    setIsSuccess(false);
    setIsError(false);
  }
  const handleShowFinalizeModal = () => setShowFinalizeModal(true);

  const [showLockFundsModal, setShowLockFundsModal] = useState(false);
  const handleCloseLockFundsModal = () => {
    setShowLockFundsModal(false)
    setErrorMsg("");
    setIsSuccess(false);
    setIsError(false);
  };
  const handleShowLockFundsModal = () => setShowLockFundsModal(true);

  const [showReceivedAndReleaseModal, setShowReceivedAndReleaseModal] = useState(false);
  const handleCloseReceivedAndReleaseModal = () => {
    setShowReceivedAndReleaseModal(false)
    setErrorMsg("");
    setIsSuccess(false);
    setIsError(false);
  };
  const handleShowReceivedAndReleaseModal = () => setShowReceivedAndReleaseModal(true);

  const [showDisputeOrderModal, setShowDisputeOrderModal] = useState(false);
  const handleCloseDisputeOrderModal = () => {
    setShowDisputeOrderModal(false)
    setErrorMsg("");
    setIsSuccess(false);
    setIsError(false);
  };
  const handleShowDisputeOrderModal = () => setShowDisputeOrderModal(true);
  useEffect(() => {
    getAllOrders();
    props.setActiveTab({
      dashboard: "",
      newOrders: "",
      orders: "",
      ordersReceived: "active",
      privacyPolicy: "",
      termsandconditions: "",
      settings: "",
      changePassword: "",
    });// eslint-disable-next-line
  }, []);
  const [view, setView] = React.useState('list');
  const [inputList, setInputList] = useState([{ category: "", description: "", fileData: "" }]);
  let [isRejecting, setIsRejecting] = useState(false);
  let [isAccepting, setIsAccepting] = useState(false);
  let [isUploading, setIsUploading] = useState(false);

  let [isReceivedAndReleaseOrder, setIsReceivedAndReleaseOrder] = useState(false);
  let [isDisputing, setIsDisputing] = useState(false);
  let [isFinalizeOrder, setIsFinalizeOrder] = useState(false);
  let [isLockingFunds, setIsLockingFunds] = useState(false);
  let [isApprovingFunds, setIsApprovingFunds] = useState(false);
  let [isRejectingDocument, setIsRejectingDocument] = useState(false);
  let [isAcceptingDocument, setIsAcceptingDocument] = useState(false);
  let [pendingOrders, setPendingOrders] = useState([]);
  let [pendingOrdersCount, setPendingOrdersCount] = useState(0);
  let [allowanceAgainstEscrow, setAllowanceAgainstEscrow] = useState(0);
  let [acceptedOrders, setAcceptedOrders] = useState([]);
  let [rejectedOrders, setRejectedOrders] = useState([]);
  let [finalizedOrders, setFinalizedOrders] = useState([]);
  let [disputedOrders, setDisputedOrders] = useState([]);
  let [underDeliveryOrders, setUnderDeliveryOrders] = useState([]);
  let [completedOrders, setCompletedOrders] = useState([]);
  let [isUploadingFileData, setIsUploadingFileData] = useState(false);
  let [fundsLockedOrders, setFundsLockedOrders] = useState([]);
  let [isError, setIsError] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);
  let [errorMsg, setErrorMsg] = useState();


  let getAllOrders = () => {
    let jwt = Cookies.get("Authorization");
    let jwtDecoded = jwtDecode(jwt);
    let importer = jwtDecoded.id;
    const timer = setTimeout(() => {
      console.log('This will run after 1 second!')
      axios
        .get(`/api/v1/importer/getOrders/OrderReceived/Pending/${importer}`)
        .then((response) => {
          console.log(response.data);
          setPendingOrders(response.data.data);
          setPendingOrdersCount(response.data.count);
        })
        .catch((error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(error.response);
          }
          setPendingOrders([]);
        });
      axios
        .get(`/api/v1/importer/getOrders/OrderReceived/Accepted/${importer}`)
        .then((response) => {
          console.log(response.data);
          setAcceptedOrders(response.data.data);
          if (order !== '' && order != null && order !== undefined) {
            let data = response.data.data.filter(item => item._id === order._id)
            console.log("Data", data);
            setOrder(data[0]);
          }
        })
        .catch((error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(error.response);
          }
          setAcceptedOrders([]);
        });
      axios
        .get(`/api/v1/importer/getOrders/OrderReceived/Rejected/${importer}`)
        .then((response) => {
          console.log(response.data);
          setRejectedOrders(response.data.data);
        })
        .catch((error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(error.response);
          }
          setRejectedOrders([]);
        });
      axios
        .get(`/api/v1/importer/getOrders/OrderReceived/Finalized/${importer}`)
        .then((response) => {
          console.log(response.data);
          setFinalizedOrders(response.data.data);
        })
        .catch((error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(error.response);
          }
          setFinalizedOrders([]);
        });
      axios
        .get(`/api/v1/importer/getOrders/OrderReceived/UnderDisputed/${importer}`)
        .then((response) => {
          console.log(response.data);
          setDisputedOrders(response.data.data);
        })
        .catch((error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(error.response);
          }
          setDisputedOrders([]);
        });
      axios
        .get(`/api/v1/importer/getOrders/OrderReceived/UnderDelivery/${importer}`)
        .then((response) => {
          console.log(response.data);
          setUnderDeliveryOrders(response.data.data);
        })
        .catch((error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(error.response);
          }
          setUnderDeliveryOrders([]);
        });
      axios
        .get(`/api/v1/importer/getOrders/OrderReceived/Completed/${importer}`)
        .then((response) => {
          console.log(response.data);
          setCompletedOrders(response.data.data);
        })
        .catch((error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(error.response);
          }
          setCompletedOrders([]);
        });
      axios
        .get(`/api/v1/importer/getOrders/OrderReceived/FundsLocked/${importer}`)
        .then((response) => {
          console.log(response.data);
          setFundsLockedOrders(response.data.data);
        })
        .catch((error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(error.response);
          }
          setFundsLockedOrders([]);
        });
      }, 3000);
    return () => clearTimeout(timer);


  };
  let acceptOrder = (orderId) => {
    setIsAccepting(true);
    let orderIdObject = {
      orderId: orderId
    }

    axios
      .post(`/api/v1/importer/acceptOrder`, orderIdObject)
      .then((response) => {
        console.log(response.data);
        let variant = "success";
        enqueueSnackbar('Order Accepted Successfully.', { variant });
        getAllOrders();
        handleCloseAcceptModal();
        setIsAccepting(false);
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.log(error.response);

        }
        setIsAccepting(false);
        let variant = "error";
        enqueueSnackbar('Unable to Accept Order.', { variant });
      });
  }
  let rejectOrder = (orderId) => {
    setIsRejecting(true);
    let orderIdObject = {
      orderId: orderId
    }
    axios
      .post(`/api/v1/importer/rejectOrder`, orderIdObject)
      .then((response) => {
        console.log(response.data);
        let variant = "success";
        enqueueSnackbar('Order Rejected Successfully.', { variant });
        getAllOrders();
        setIsRejecting(false);
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.log(error.response);
        }
        let variant = "error";
        enqueueSnackbar('Unable to Reject Order.', { variant });
        setIsRejecting(false);
      });
  }
  let fileSelectHandler = (event, index) => {
    if (event.target.files[0] !== undefined) {
      setIsUploadingFileData(true);

      let variant = "info";
      enqueueSnackbar('Please wait, File is uploading.', { variant });
      let selectedFile = event.target.files[0]
      let fileData = new FormData();
      fileData.append("image", selectedFile);
      axios.post("upload/uploadtos3", fileData).then(
        (response) => {
          console.log("response", response);
          let newArr = [...inputList];

          newArr[index].fileData = response.data.url;
          setInputList(newArr);
          setIsUploadingFileData(false);
          let variant = "success";
          enqueueSnackbar('File Uploaded to S3 Successfully', { variant });
        },
        (error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(error);
            console.log(error.response);
          }
          setIsUploadingFileData(false);
          let variant = "error";
          enqueueSnackbar('Unable to Upload File to S3 .', { variant });
        }
      );
    }
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleAddClick = () => {
    setInputList([...inputList, { category: "", description: "", fileData: "" }]);
  };
  let uploadDocument = (e) => {
    e.preventDefault();
    setIsUploading(true);
    setIsError(false);
    if (inputList.length > 0) {
      inputList.forEach((file, index) => {
        if (file.category === undefined || file.category === "") {
          setIsError(true);
          setErrorMsg(`Please Add a File Category at ${index + 1} position`);
          setIsUploading(false);
          return;
        } else
          if (file.description === "" || file.description === "undefined") {
            setIsError(true);
            setErrorMsg(`Please Add a File Description at ${index + 1} position`);
            setIsUploading(false);
            return;
          } else
            if (file.fileData === "" || file.fileData === "undefined") {
              setIsError(true);
              setErrorMsg(`Please Upload a File at ${index + 1} position`);
              setIsUploading(false);
              return;
            }
      });
    }

    let catagoryArray = [];
    let descriptionArray = [];
    let fileArray = []
    for (let i = 0; i < inputList.length; i++) {
      catagoryArray.push(inputList[i].category);
      descriptionArray.push(inputList[i].description);
      fileArray.push(inputList[i].fileData);
    }
    let documentData = {
      description: descriptionArray,
      documentNames: catagoryArray,
      file: fileArray,
      orderId: order._id,
    }

    axios.post("api/v1/importer/uploadDocuments", documentData).then(
      (response) => {
        console.log("response", response);
        setIsUploading(false);
        setInputList([{ category: "", description: "", fileData: "" }])
        getAllOrders();
        let variant = "success";
        enqueueSnackbar('Documents Uploaded Successfully.', { variant });

      },
      (error) => {
        if (process.env.NODE_ENV === "development") {
          console.log(error);
          console.log(error.response);
        }
        setIsUploading(false);
        let variant = "error";
        enqueueSnackbar('Unable to Uploade Documents.', { variant });
      }
    );
  }
  let rejectDocument = (documentId, reason) => {
    setIsRejectingDocument(true);
    let rejectDocumentObject = {
      orderId: order._id,
      documentId: [documentId],
      rejectionReason: reason
    }
    console.log(rejectDocumentObject);
    axios.post("api/v1/importer/rejectFile", rejectDocumentObject).then(
      (response) => {
        console.log("response", response);
        setIsRejectingDocument(false);
        getAllOrders();
        let variant = "success";
        enqueueSnackbar('Document Rejected Successfully.', { variant });
      },
      (error) => {
        if (process.env.NODE_ENV === "development") {
          console.log(error);
          console.log(error.response);
        }
        setIsRejectingDocument(false);
        let variant = "error";
        enqueueSnackbar('Unable to Reject Document.', { variant });
      })
  }
  let acceptDocument = (documentId) => {
    setIsAcceptingDocument(true);
    let acceptDocumentObject = {
      orderId: order._id,
      documentId: [documentId]
    }
    console.log(acceptDocumentObject);
    axios.post("api/v1/importer/acceptFile", acceptDocumentObject).then(
      (response) => {
        console.log("response", response);
        setIsAcceptingDocument(false);
        getAllOrders();
        let variant = "success";
        enqueueSnackbar('Document Accepted Successfully.', { variant });
      },
      (error) => {
        if (process.env.NODE_ENV === "development") {
          console.log(error);
          console.log(error.response);
        }
        setIsAcceptingDocument(false);
        let variant = "error";
        enqueueSnackbar('Unable to Accept Document.', { variant });
      })
  }
  let finalizeOrder = async () => {
    setIsFinalizeOrder(true);
    await loadWeb3();
    const web3 = window.web3
    const accounts = await web3.eth.getAccounts();
    const type = await web3.eth.net.getNetworkType();
    console.log("type", type);
    if (type === 'goerli') {
      let finlizeOrderObject = {
        orderId: order._id,
        importerAddress: accounts[0]
      }
      axios.post("api/v1/importer/finalizeStatus", finlizeOrderObject).then(
        (response) => {
          console.log("response", response);
          setIsFinalizeOrder(false);
          handleCloseFinalizeModal();
          getAllOrders();
          let variant = "success";
          enqueueSnackbar('Order Finalized Successfully.', { variant });
        },
        (error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(error);
            console.log(error.response);
          }
          setIsFinalizeOrder(false);
          let variant = "error";
          enqueueSnackbar('Unable to Finalize Order.', { variant });
        })
    } else {
      let variant = "error";
      enqueueSnackbar('Please Connect to Goerli Test Network.', { variant });
    }
  }
  let loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }
  }

  let approveFunds = async (orderData) => {
    console.log("orderData", orderData);
    setIsApprovingFunds(true);

    await loadWeb3();
    const web3 = window.web3
    const accounts = await web3.eth.getAccounts();
    const type = await web3.eth.net.getNetworkType();
    console.log("type", type);
    const address = Addresses.USDTTokenAddress;
    const abi = USDTToken;
    if (type === 'goerli') {
      var myContractInstance = await new web3.eth.Contract(abi, address);
      console.log("contract3", orderData.adminCommission / 1 + orderData.numberOfTokens / 1);
      let USDT = orderData.adminCommission / 1 + orderData.numberOfTokens / 1;
      await myContractInstance.methods.approve(Addresses.EscrowAddress, USDT.toString()).send({ from: accounts[0] }, (err, response) => {
        console.log('get transaction', err, response);
        if (err !== null) {
          console.log("err", err);
          let variant = "error";
          enqueueSnackbar('User Canceled Transaction', { variant });
          setIsApprovingFunds(false);
        }
      })
        .on('receipt', (receipt) => {
          console.log("receipt", receipt);
          let variant = "success";
          getAllOrders()
          enqueueSnackbar('Funds Approved Successfully.', { variant });
          setIsApprovingFunds(false);
        })
    } else {
      setIsApprovingFunds(false);
      let variant = "error";
      enqueueSnackbar('Please Connect to Goerli Test Network.', { variant });
    }
  }
  let lockFunds = async () => {
    console.log(order._id);
    setIsLockingFunds(true);
    let lockFundsObject = {
      orderId: order._id,
    }
    await loadWeb3();
    const web3 = window.web3
    const accounts = await web3.eth.getAccounts();
    const type = await web3.eth.net.getNetworkType();
    console.log("type", type);
    const address = Addresses.EscrowAddress;
    const abi = Escrow;
    if (type === 'goerli') {
      var myContractInstance = await new web3.eth.Contract(abi, address);
      console.log("contract", order.adminCommission + order.numberOfTokens);
      let USDT = order.adminCommission / 1 + order.numberOfTokens / 1;
      await myContractInstance.methods.lockFundsinAgreement(accounts[0], order.agreementAddress, USDT.toString()).send({ from: accounts[0] }, (err, response) => {
        console.log('get transaction', err, response);
        if (err !== null) {
          console.log("err", err);
          setIsLockingFunds(false);
        }
      })
        .on('receipt', (receipt) => {
          console.log("receipt", receipt);
          axios.post("api/v1/importer/fundsLocked", lockFundsObject).then(
            (response) => {
              console.log("response", response);
              setIsLockingFunds(false);
              handleCloseLockFundsModal()
              getAllOrders();
              let variant = "success";
              enqueueSnackbar('Funds Locked Successfully.', { variant });
            },
            (error) => {
              if (process.env.NODE_ENV === "development") {
                console.log(error);
                console.log(error.response);
              }
              setIsLockingFunds(false);
              let variant = "error";
              enqueueSnackbar('Unable to Lock Funds.', { variant });

            })
        })
    } else {
      let variant = "error";
      enqueueSnackbar('Please Connect to Goerli Test Network.', { variant });
    }
  }
  let receiveAndReleaseFunds = async () => {
    console.log(order._id);
    setIsReceivedAndReleaseOrder(true);
    let receiveAndReleaseFundsObject = {
      orderId: order._id,
    }
    await loadWeb3();
    const web3 = window.web3
    const accounts = await web3.eth.getAccounts();
    const type = await web3.eth.net.getNetworkType();
    console.log("type", type);
    const address = order.agreementAddress;
    const abi = Agreement;
    if (type === 'goerli') {
      var myContractInstance = await new web3.eth.Contract(abi, address);
      console.log("contract", order.numberOfTokens);
      await myContractInstance.methods.releaseFunds().send({ from: accounts[0] }, (err, response) => {
        console.log('get transaction', err, response);
        if (err !== null) {
          console.log("err", err);
          setIsReceivedAndReleaseOrder(false);
        }
      })
        .on('receipt', (receipt) => {
          console.log("receipt", receipt);
          axios.post("api/v1/importer/releasefunds", receiveAndReleaseFundsObject).then(
            (response) => {
              console.log("response", response);
              setIsReceivedAndReleaseOrder(false);
              handleCloseReceivedAndReleaseModal();
              getAllOrders();
              let variant = "success";
              enqueueSnackbar('Funds Released Successfully.', { variant });
            },
            (error) => {
              if (process.env.NODE_ENV === "development") {
                console.log(error);
                console.log(error.response);
              }
              setIsReceivedAndReleaseOrder(false);
              let variant = "error";
              enqueueSnackbar('Unable to Release Funds.', { variant });
            })
        })
    } else {
      let variant = "error";
      enqueueSnackbar('Please Connect to Goerli Test Network.', { variant });
    }
  }


  let disputeOrder = (inputListData, disputeReason) => {
    console.log(order._id, inputListData, disputeReason);
    setIsDisputing(true);
    let catagoryArray = [];
    let descriptionArray = [];
    let fileArray = [];
    for (let i = 0; i < inputListData.length; i++) {
      catagoryArray.push(inputListData[i].category);
      descriptionArray.push(inputListData[i].description);
      fileArray.push(inputListData[i].fileData);
    }
    let disputeData = {
      orderId: order._id,
      documentNames: catagoryArray,
      description: descriptionArray,
      disputeReason: disputeReason,
      disputedDocuments: fileArray,
    }

    axios.post("api/v1/importer/haveDispute", disputeData).then(
      (response) => {
        console.log("response", response);
        setIsDisputing(false);
        handleCloseDisputeOrderModal();
        getAllOrders();
        let variant = "success";
        enqueueSnackbar('Order Disputed Successfully.', { variant });
      },
      (error) => {
        if (process.env.NODE_ENV === "development") {
          console.log(error);
          console.log(error.response);
        }
        setIsDisputing(false);
        let variant = "error";
        enqueueSnackbar('Unable to Dispute Order.', { variant });
      })
  }

  const handleChange = (event, nextView) => {
    console.log('nextView', nextView);
    setView(nextView);
  };

  const getAllowance = useCallback(async () => {
    await loadWeb3();
    const web3 = window.web3
    const accounts = await web3.eth.getAccounts();
    const type = await web3.eth.net.getNetworkType();
    console.log("type", type);
    const address = Addresses.USDTTokenAddress;
    const abi = USDTToken;
    if (type === 'goerli') {
      var myContractInstance = await new web3.eth.Contract(abi, address);

      let allowance = await myContractInstance.methods.allowance(accounts[0], Addresses.EscrowAddress).call();
      setAllowanceAgainstEscrow(allowance);
      console.log("allowance", allowance);
    } else {
      let variant = "error";
      enqueueSnackbar('Please Connect to Goerli Test Network.', { variant });
    }
  })
  useEffect(() => {
    getAllowance()
    // eslint-disable-next-line
  }, [getAllOrders]);
  return (
    <>
      <div className="card">
        <ul className="breadcrumb" style={{ backgroundColor: "#174153" }}>
          <li className="breadcrumb-item">
            <a href="/">Dashboard</a>
          </li>
          <li className="breadcrumb-item active">Orders Received</li>
        </ul>
        <div className="container">
          <ToggleButtonGroup style={{ float: 'right' }} orientation="horizontal" value={view} exclusive onChange={handleChange}>
            <ToggleButton value="list" aria-label="list">
              <ViewListIcon />
            </ToggleButton>
            <ToggleButton value="module" aria-label="module">
              <ViewModuleIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="card-body">

          <div className="row">

            <div className="col-sm-12">
              <div className="form-group">
                {view === 'list' ? (
                  <TabPanel
                    setValue={setValue}
                    value={value}
                    pendingOrders={pendingOrders}
                    pendingOrdersCount={pendingOrdersCount}
                    acceptedOrders={acceptedOrders}
                    rejectedOrders={rejectedOrders}
                    finalizedOrders={finalizedOrders}
                    disputedOrders={disputedOrders}
                    underDeliveryOrders={underDeliveryOrders}
                    completedOrders={completedOrders}
                    fundsLockedOrders={fundsLockedOrders}
                    setOrder={setOrder}
                    setShow={setShow}
                    handleShowAcceptModal={handleShowAcceptModal}
                    handleShowUploadModal={handleShowUploadModal}
                    handleShowAcceptRejectModal={handleShowAcceptRejectModal}
                    handleShowFinalizeModal={handleShowFinalizeModal}
                    handleShowLockFundsModal={handleShowLockFundsModal}
                    rejectOrder={rejectOrder}
                    isRejecting={isRejecting}
                    handleShowReceivedAndReleaseModal={handleShowReceivedAndReleaseModal}
                    handleShowDisputeOrderModal={handleShowDisputeOrderModal}
                    approveFunds={approveFunds}
                    allowanceAgainstEscrow={allowanceAgainstEscrow}
                    isApprovingFunds={isApprovingFunds}
                  ></TabPanel>
                ) : (
                  <CardsTabPanel
                    setValue={setValue}
                    value={value}
                    pendingOrders={pendingOrders}
                    pendingOrdersCount={pendingOrdersCount}
                    acceptedOrders={acceptedOrders}
                    rejectedOrders={rejectedOrders}
                    finalizedOrders={finalizedOrders}
                    disputedOrders={disputedOrders}
                    underDeliveryOrders={underDeliveryOrders}
                    completedOrders={completedOrders}
                    fundsLockedOrders={fundsLockedOrders}
                    setOrder={setOrder}
                    setShow={setShow}
                    handleShowAcceptModal={handleShowAcceptModal}
                    handleShowUploadModal={handleShowUploadModal}
                    handleShowAcceptRejectModal={handleShowAcceptRejectModal}
                    handleShowFinalizeModal={handleShowFinalizeModal}
                    handleShowLockFundsModal={handleShowLockFundsModal}
                    rejectOrder={rejectOrder}
                    isRejecting={isRejecting}
                    handleShowReceivedAndReleaseModal={handleShowReceivedAndReleaseModal}
                    handleShowDisputeOrderModal={handleShowDisputeOrderModal}
                    approveFunds={approveFunds}
                    isApprovingFunds={isApprovingFunds}
                  ></CardsTabPanel>
                )}


              </div>
            </div>
          </div>
        </div>
      </div>

      <OrderDetailModal
        order={order}
        show={show}
        handleClose={handleClose}
      />
      <OrderAcceptModal
        order={order}
        acceptOrder={acceptOrder}
        isAccepting={isAccepting}
        handleCloseAcceptModal={handleCloseAcceptModal}
        showAcceptModal={showAcceptModal}
      />
      <UploadDocumentModal
        handleCloseUploadModal={handleCloseUploadModal}
        isUploading={isUploading}
        errorMsg={errorMsg}
        isSuccess={isSuccess}
        isUploadingFileData={isUploadingFileData}
        isError={isError}
        inputList={inputList}
        handleAddClick={handleAddClick}
        handleRemoveClick={handleRemoveClick}
        fileSelectHandler={fileSelectHandler}
        setInputList={setInputList}
        uploadDocument={uploadDocument}
        showUploadModal={showUploadModal}
      />
      <AcceptRejectImporterDocumentModal
        isRejectingDocument={isRejectingDocument}
        isAcceptingDocument={isAcceptingDocument}
        rejectDocument={rejectDocument}
        acceptDocument={acceptDocument}
        showAcceptRejectModal={showAcceptRejectModal}
        handleCloseAcceptRejectModal={handleCloseAcceptRejectModal}
        order={order}
      />
      <FinalizeConfirmationModal
        handleCloseFinalizeModal={handleCloseFinalizeModal}
        showFinalizeModal={showFinalizeModal}
        order={order}
        errorMsg={errorMsg}
        isError={isError}
        isSuccess={isSuccess}
        isFinalizeOrder={isFinalizeOrder}
        finalizeOrder={finalizeOrder}
      />
      <LockingFundsConfirmationModal
        showLockFundsModal={showLockFundsModal}
        handleCloseLockFundsModal={handleCloseLockFundsModal}
        order={order}
        lockFunds={lockFunds}
        isError={isError}
        errorMsg={errorMsg}
        isSuccess={isSuccess}
        isLockingFunds={isLockingFunds}
      />
      <ReceivedandReleaseFundsModal
        showLockFundsModal={showLockFundsModal}
        handleCloseReceivedAndReleaseModal={handleCloseReceivedAndReleaseModal}
        order={order}
        showReceivedAndReleaseModal={showReceivedAndReleaseModal}
        isError={isError}
        errorMsg={errorMsg}
        isSuccess={isSuccess}
        isReceivedAndReleaseOrder={isReceivedAndReleaseOrder}
        receiveAndReleaseFunds={receiveAndReleaseFunds}
      />
      <DisputeOrderModal
        handleCloseDisputeOrderModal={handleCloseDisputeOrderModal}
        showDisputeOrderModal={showDisputeOrderModal}
        order={order}
        errorMsg={errorMsg}
        isError={isError}
        isSuccess={isSuccess}
        isDisputing={isDisputing}
        disputeOrder={disputeOrder}
      />
    </>
  );
}

export default OrdersReceived;
