import axios from "axios";
// import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { useSnackbar } from 'notistack';
import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Link, Redirect } from "react-router-dom";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import defaultPhoto from "../../../assets/img/img-04.jpg";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import Footer from "../../../components/Footers/Footer";
import Header from "../../../components/Headers/Header";

function KYCScreen(props) {
    const { enqueueSnackbar } = useSnackbar();
    let [isLoading, setIsLoading] = useState(false);
    let [isError, setIsError] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);

    let [msg, setMsg] = useState("");
    let [products, setProducts] = useState([]);
    let [factoryName, setFactoryName] = useState("");

    let [selfiePhoto, setSelfiePhoto] = useState(defaultPhoto);
    let [isUploadingSelfiePhoto, setIsUploadingSelfiePhoto] = useState(false);
    let [businessCretificatePhoto, setBusinessCretificatePhoto] = useState(defaultPhoto);
    let [isUploadingBusinessCretificatePhoto, setIsUploadingBusinessCretificatePhoto] = useState(false);
    let [passportPhoto, setPassportPhoto] = useState(defaultPhoto);
    let [isUploadingPassportPhoto, setIsUploadingPassportPhoto] = useState(false);
    let [passportSelfiePhoto, setPassportSelfiePhoto] = useState(defaultPhoto);
    let [isUploadingPassportSelfiePhoto, setIsUploadingPassportSelfiePhoto] = useState(false);
    let [isUploadingOtherPhoto, setIsUploadingOtherPhotos] = useState(false);

    let jwt = localStorage.getItem("Token")
    let userId;
    let jwtDecoded;
    if (jwt) {
        jwtDecoded = jwtDecode(jwt);
        userId = jwtDecoded.id;
    }
    let handleSubmitEvent = (event) => {
        setMsg("");
        setIsError(false);
        setIsLoading(true);
        event.preventDefault();
        let kycData = {
            status: 'pending',
            userId: userId,
            factories: factoryName,
            passportPicture: passportPhoto,
            businessCertificate: businessCretificatePhoto,
            selfiePictures: selfiePhoto,
            passportsSelfie: passportSelfiePhoto,
            picturesOfProducts: products
        }
        axios
            .post("/api/v1/auth/user/KYCsubmission", kycData)
            .then((response) => {
                if (process.env.NODE_ENV === "development")
                    console.log("response", response);
                setIsSuccess(true);
                setIsError(false);
                setIsLoading(false);
                setMsg(response.data)
            })
            .catch((error) => {
                setIsSuccess(false);
                setIsError(true);
                if (error.response !== undefined) {
                    setMsg("Unknown Error Occured, try again.");
                } else {
                    setMsg("Unknown Error Occured, try again.");
                }
                setIsError(true);
                setIsLoading(false);
            });
    };
    let onChangeSelfieHandler = (event) => {
        if (event.target.files[0] !== undefined) {
            setIsUploadingSelfiePhoto(true);
            let selectedFile = event.target.files[0]
            let fileData = new FormData();
            fileData.append("image", selectedFile);
            axios.post("upload/uploadtos3", fileData).then(
                (response) => {
                    if (process.env.NODE_ENV === "development")
                        console.log("response", response);
                    setSelfiePhoto(response.data.url);
                    setIsUploadingSelfiePhoto(false);
                    let variant = "success";
                    enqueueSnackbar('Image Uploaded to S3 Successfully', { variant });
                },
                (error) => {
                    if (process.env.NODE_ENV === "development") {
                        console.log(error);
                        console.log(error.response);
                    }
                    setIsUploadingSelfiePhoto(false);
                    let variant = "error";
                    enqueueSnackbar('Unable to Upload Image to S3 .', { variant });
                }
            );
        }
    }
    let onChangeBusinessCertificateHandler = (event) => {
        if (event.target.files[0] !== undefined) {
            setIsUploadingBusinessCretificatePhoto(true);
            let selectedFile = event.target.files[0]
            let fileData = new FormData();
            fileData.append("image", selectedFile);
            axios.post("upload/uploadtos3", fileData).then(
                (response) => {
                    if (process.env.NODE_ENV === "development")
                        console.log("response", response);
                    setBusinessCretificatePhoto(response.data.url);
                    setIsUploadingBusinessCretificatePhoto(false);
                    let variant = "success";
                    enqueueSnackbar('Image Uploaded to S3 Successfully', { variant });
                },
                (error) => {
                    if (process.env.NODE_ENV === "development") {
                        console.log(error);
                        console.log(error.response);
                    }
                    setIsUploadingBusinessCretificatePhoto(false);
                    let variant = "error";
                    enqueueSnackbar('Unable to Upload Image to S3 .', { variant });
                }
            );
        }
    }
    let onChangePassportHandler = (event) => {
        if (event.target.files[0] !== undefined) {
            setIsUploadingPassportPhoto(true);
            let selectedFile = event.target.files[0]
            let fileData = new FormData();
            fileData.append("image", selectedFile);
            axios.post("upload/uploadtos3", fileData).then(
                (response) => {
                    if (process.env.NODE_ENV === "development")
                        console.log("response", response);
                    setPassportPhoto(response.data.url);
                    setIsUploadingPassportPhoto(false);
                    let variant = "success";
                    enqueueSnackbar('Image Uploaded to S3 Successfully', { variant });
                },
                (error) => {
                    if (process.env.NODE_ENV === "development") {
                        console.log(error);
                        console.log(error.response);
                    }
                    setIsUploadingPassportPhoto(false);
                    let variant = "error";
                    enqueueSnackbar('Unable to Upload Image to S3 .', { variant });
                }
            );
        }
    }
    let onChangePassportSelfieHandler = (event) => {
        if (event.target.files[0] !== undefined) {

            setIsUploadingPassportSelfiePhoto(true);
            let selectedFile = event.target.files[0]
            let fileData = new FormData();
            fileData.append("image", selectedFile);
            axios.post("upload/uploadtos3", fileData).then(
                (response) => {
                    if (process.env.NODE_ENV === "development")
                        console.log("response", response);
                    setPassportSelfiePhoto(response.data.url);
                    setIsUploadingPassportSelfiePhoto(false);
                    let variant = "success";
                    enqueueSnackbar('Image Uploaded to S3 Successfully', { variant });
                },
                (error) => {
                    if (process.env.NODE_ENV === "development") {
                        console.log(error);
                        console.log(error.response);
                    }
                    setIsUploadingPassportSelfiePhoto(false);
                    let variant = "error";
                    enqueueSnackbar('Unable to Upload Image to S3 .', { variant });
                }
            );
        }
    }
    let onChangePictureHandler = (event) => {
        let fileArray = []
        for (let i = 0; i < event.target.files.length; i++) {
            fileArray.push(event.target.files[i]);
        }

        
        setIsUploadingOtherPhotos(true);
        let fileData = new FormData();

        for (let i = 0; i < fileArray.length; i++) {
            fileData.append(`image`, fileArray[i]);
        }
        fileData.append(`noOffiles`, fileArray.length);
        axios.post("upload/uploadtos3array", fileData).then(
            (response) => {
                if (process.env.NODE_ENV === "development")
                    console.log("response", response);
                setProducts(response.data.url);
                setIsUploadingOtherPhotos(false);
                let variant = "success";
                enqueueSnackbar('Images Uploaded to S3 Successfully', { variant });
            },
            (error) => {
                if (process.env.NODE_ENV === "development") {
                    console.log(error);
                    console.log(error.response);
                }
                setIsUploadingOtherPhotos(false);
                let variant = "error";
                enqueueSnackbar('Unable to Upload Images to S3 .', { variant });
            }
        );
    };



    if (jwt === undefined) {
        return <Redirect to='/login'></Redirect>
    }
    else {
        return (

            <div className="account-page">
                <div
                    className="main-wrapper">
                    <div
                        className="home-section home-full-height">
                        <Header />
                        <div className="content" style={{ paddingTop: "180px", height: "180vh" }} position="absolute">
                            <div className="container-fluid">
                                <div className="row" style={{ height: `${props.windowHeight}`, marginRight: "px" }} >
                                    <div className="col-md-8 offset-md-2">
                                        <div className="account-content">
                                            <div className="row align-items-center justify-content-center">
                                                <div className="col-md-11 col-lg-11 login-right">
                                                    <div className="login-header">
                                                        <h3 style={{ textAlign: "center" }}>KYC Information</h3>
                                                    </div>
                                                    <form onSubmit={handleSubmitEvent}>
                                                        <div className="row">
                                                            <div className="col-12 col-md-6">
                                                                <label className="focus-label">Selfie Photo</label>
                                                                <div className="form-group">
                                                                    <div className="change-avatar">
                                                                        <div className="profile-img">
                                                                            <div
                                                                                style={{
                                                                                    background: "#E9ECEF",
                                                                                    width: "100px",
                                                                                    height: "100px",
                                                                                }}
                                                                            >
                                                                                <img src={selfiePhoto} alt="Selfie" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="upload-img">
                                                                            <div
                                                                                className="change-photo-btn"
                                                                                style={{ backgroundColor: "rgb(23, 65, 83)" }}
                                                                            >
                                                                                {isUploadingSelfiePhoto ? (
                                                                                    <div className="text-center">
                                                                                        <Spinner
                                                                                            animation="border"
                                                                                            role="status"
                                                                                            style={{ color: "#fff" }}
                                                                                        >
                                                                                        </Spinner>
                                                                                    </div>
                                                                                ) : (
                                                                                    <span><i className="fa fa-upload"></i>Upload photo</span>
                                                                                )}
                                                                                <input
                                                                                    name="sampleFile"
                                                                                    type="file"
                                                                                    className="upload"
                                                                                    accept=".png,.jpg,.jpeg"
                                                                                    onChange={onChangeSelfieHandler}
                                                                                />
                                                                            </div>
                                                                            <small className="form-text text-muted">
                                                                                Allowed JPG, JPEG, PNG. Max size of 5MB
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="focus-label">Business Certificate Photo</label>
                                                                <div className="form-group">
                                                                    <div className="change-avatar">
                                                                        <div className="profile-img">
                                                                            <div
                                                                                style={{
                                                                                    background: "#E9ECEF",
                                                                                    width: "100px",
                                                                                    height: "100px",
                                                                                }}
                                                                            >
                                                                                <img src={businessCretificatePhoto} alt="Business Certificate" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="upload-img">
                                                                            <div
                                                                                className="change-photo-btn"
                                                                                style={{ backgroundColor: "rgb(23, 65, 83)" }}
                                                                            >
                                                                                {isUploadingBusinessCretificatePhoto ? (
                                                                                    <div className="text-center">
                                                                                        <Spinner
                                                                                            animation="border"
                                                                                            role="status"
                                                                                            style={{ color: "#fff" }}
                                                                                        >
                                                                                        </Spinner>
                                                                                    </div>
                                                                                ) : (
                                                                                    <span><i className="fa fa-upload"></i>Upload photo</span>
                                                                                )}
                                                                                <input
                                                                                    name="sampleFile"
                                                                                    type="file"
                                                                                    className="upload"
                                                                                    accept=".png,.jpg,.jpeg"
                                                                                    onChange={onChangeBusinessCertificateHandler}
                                                                                />
                                                                            </div>
                                                                            <small className="form-text text-muted">
                                                                                Allowed JPG, JPEG, PNG. Max size of 5MB
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="focus-label">Passport Picture</label>
                                                                <div className="form-group">
                                                                    <div className="change-avatar">
                                                                        <div className="profile-img">
                                                                            <div
                                                                                style={{
                                                                                    background: "#E9ECEF",
                                                                                    width: "100px",
                                                                                    height: "100px",
                                                                                }}
                                                                            >
                                                                                <img src={passportPhoto} alt="Passport" />
                                                                            </div>
                                                                        </div>

                                                                        <div className="upload-img">
                                                                            <div
                                                                                className="change-photo-btn"
                                                                                style={{ backgroundColor: "rgb(23, 65, 83)" }}
                                                                            >
                                                                                {isUploadingPassportPhoto ? (
                                                                                    <div className="text-center">
                                                                                        <Spinner
                                                                                            animation="border"
                                                                                            role="status"
                                                                                            style={{ color: "#fff" }}
                                                                                        >
                                                                                        </Spinner>
                                                                                    </div>
                                                                                ) : (
                                                                                    <span><i className="fa fa-upload"></i>Upload photo</span>
                                                                                )}
                                                                                <input
                                                                                    name="sampleFile"
                                                                                    type="file"
                                                                                    className="upload"
                                                                                    accept=".png,.jpg,.jpeg"
                                                                                    onChange={onChangePassportHandler}
                                                                                />
                                                                            </div>
                                                                            <small className="form-text text-muted">
                                                                                Allowed JPG, JPEG, PNG. Max size of 5MB
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="focus-label">Passport Picture with Selfie</label>
                                                                <div className="form-group">
                                                                    <div className="change-avatar">
                                                                        <div className="profile-img">
                                                                            <div
                                                                                style={{
                                                                                    background: "#E9ECEF",
                                                                                    width: "100px",
                                                                                    height: "100px",
                                                                                }}
                                                                            >
                                                                                <img src={passportSelfiePhoto} alt="Selfie with Passport" />
                                                                            </div>
                                                                        </div>

                                                                        <div className="upload-img">
                                                                            <div
                                                                                className="change-photo-btn"
                                                                                style={{ backgroundColor: "rgb(23, 65, 83)" }}
                                                                            >
                                                                                {isUploadingPassportSelfiePhoto ? (
                                                                                    <div className="text-center">
                                                                                        <Spinner
                                                                                            animation="border"
                                                                                            role="status"
                                                                                            style={{ color: "#fff" }}
                                                                                        >
                                                                                        </Spinner>
                                                                                    </div>
                                                                                ) : (
                                                                                    <span><i className="fa fa-upload"></i>Upload photo</span>
                                                                                )}
                                                                                <input
                                                                                    name="sampleFile"
                                                                                    type="file"
                                                                                    className="upload"
                                                                                    accept=".png,.jpg,.jpeg"
                                                                                    onChange={onChangePassportSelfieHandler}
                                                                                />
                                                                            </div>
                                                                            <small className="form-text text-muted">
                                                                                Allowed JPG, JPEG, PNG. Max size of 5MB
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12">
                                                                <label className="focus-label">
                                                                    <span>Upload other pictures</span>
                                                                    <small className="form-text text-muted">
                                                                        e.g.  Products, Factories and Inventories
                                                                    </small>
                                                                </label>
                                                                <div className="form-group">
                                                                    <div className="change-avatar">
                                                                        <div className="upload-img">
                                                                            <div
                                                                                className="change-photo-btn"
                                                                                style={{ backgroundColor: "rgb(23, 65, 83)" }}
                                                                            >
                                                                                {isUploadingOtherPhoto ? (
                                                                                    <div className="text-center">
                                                                                        <Spinner
                                                                                            animation="border"
                                                                                            role="status"
                                                                                            style={{ color: "#fff" }}
                                                                                        >
                                                                                        </Spinner>
                                                                                    </div>
                                                                                ) : (
                                                                                    <span><i className="fa fa-upload"></i>Upload photos</span>
                                                                                )}
                                                                                <input
                                                                                    name="sampleFile"
                                                                                    type="file"
                                                                                    multiple
                                                                                    className="upload"
                                                                                    accept=".png,.jpg,.jpeg"
                                                                                    onChange={onChangePictureHandler}
                                                                                />
                                                                            </div>
                                                                            <small className="form-text text-muted">
                                                                                Allowed JPG, JPEG, PNG. Max size of 5MB
                                                                            </small>
                                                                        </div>

                                                                        <div className="col-3 col-md-3 text-muted" style={{ marginBottom: '20px' }}>
                                                                            {products.length}
                                                                            Photos Selected
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12">
                                                                <div className="form-group form-focus focused">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control floating"
                                                                        onChange={(e) => {
                                                                            setFactoryName(e.target.value)
                                                                        }}
                                                                        defaultValue={factoryName}
                                                                        required
                                                                    />
                                                                    <label className="focus-label">Business Name</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {isError ? (<div className="text-center">
                                                            <p style={{ color: "red" }}>{msg}</p>
                                                        </div>) : (null)}
                                                        {isSuccess ? (<div className="text-center">
                                                            <p style={{ color: "green" }}>{msg}</p>
                                                        </div>) : (null)}

                                                        {isLoading ? (
                                                            <div className="text-center">
                                                                <Spinner
                                                                    animation="border"
                                                                    role="status"
                                                                    style={{ color: "#00d0f1" }}
                                                                >
                                                                    <span className="sr-only">Loading...</span>
                                                                </Spinner>
                                                            </div>
                                                        ) : (
                                                            <button
                                                                className="btn btn-block btn-lg login-btn"
                                                                type="submit"
                                                            >
                                                                Submit
                                                            </button>
                                                        )}
                                                        <Link to="/login" className="btn btn-block btn-lg login-btn">Login</Link>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer position={""} />
                </div>
            </div >
        );
    }
}

export default KYCScreen;
