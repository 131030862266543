import {
    Card,
    CardContent, Grid,
    Typography
} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import OrderDetailModal from "../../../../components/Modals/OrderDetailModal";


const useStyles = makeStyles({
    root: {
        minWidth: 250,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

function DisputedResolved(props) {
    let [disputedOrders, setDisputedOrders] = useState([]);
    const handleClose = () => setShow(false);
    let [show, setShow] = useState(false);
    let [view, setView] = useState('list');
    let [order, setOrder] = useState();

    const classes = useStyles();
    let getDisputedOrders = () => {
        axios.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get(
            "Authorization"
        )}`;
        axios
            .get(`/api/v1/admin/getOrders/ResolvedDisputed`)
            .then((response) => {
                console.log("response.data", response.data.data);
                setDisputedOrders(response.data.data);
            })
            .catch((error) => {
                console.log(error.response);
            });
    };

    useEffect(() => {
        getDisputedOrders();
        props.setActiveTab({
            dashboard: "",
            totalUserAccount: "",
            nonKYCAccount: "",
            pendingUserAccount: "",
            disputedOrders: "",
            tradeListOrders: "",
            earningsList: "",
            referralEarnings: "",
            resolvedDisputedOrders: "active",
            settings: "",
            changePassword: "",
        });// eslint-disable-next-line
    }, []);
    const handleChange = (event, nextView) => {
        console.log('nextView', nextView);
        setView(nextView);
    };
    return (
        <div className="card">
            <ul className="breadcrumb" style={{ backgroundColor: "#174153" }}>
                <li className="breadcrumb-item">
                    <a href="/">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Resolved Disputes</li>
            </ul>
            <div className="container">
                <ToggleButtonGroup style={{ float: 'right' }} orientation="horizontal" value={view} exclusive onChange={handleChange}>
                    <ToggleButton value="list" aria-label="list">
                        <ViewListIcon />
                    </ToggleButton>
                    <ToggleButton value="module" aria-label="module">
                        <ViewModuleIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            <div className="card-body">
                {view === 'list' ? (
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">

                                <div
                                    className="table-responsive"
                                    style={{ paddingTop: "20px" }}
                                >
                                    {disputedOrders.length === 0 ? (
                                        <Card variant="outlined" className="text-center" style={{ padding: "40px", marginTop: '20px', marginBottom: '20px' }}>
                                            <Typography variant="body2" className="text-center" color="textSecondary" component="p"  >
                                                <strong>No items to display </strong>
                                            </Typography>
                                        </Card>
                                    ) : (
                                        <table className="table table-hover table-center mb-0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Exporter</th>
                                                    <th>Importer</th>
                                                    <th>Documents by Importer</th>
                                                    <th>Documents by Exporter</th>
                                                    <th>Reason of Dispute</th>
                                                    <th>Documents of Dispute</th>
                                                    <th>Created At</th>
                                                    <th>Upadated At</th>
                                                    <th>Contract Time</th>
                                                    <th style={{ textAlign: 'center', alignContent: 'center' }}>Price</th>
                                                    <th>Admin Commission (%)</th>
                                                    <th>Agreement Address</th>
                                                    <th style={{ textAlign: 'center' }} >Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {disputedOrders.map((i, index) => (
                                                    <tr key={i._id}>
                                                        <td>{index + 1}</td>
                                                        {i.exporterId !== null ? (
                                                            <td>{i.exporterId.name}</td>
                                                        ) : (
                                                            <td></td>
                                                        )}
                                                        {i.importerId !== null ? (
                                                            <td>{i.importerId.name}</td>
                                                        ) : (
                                                            <td></td>
                                                        )}
                                                        <td>
                                                            {i.documentsByImporter.map((file, jindex) => {
                                                                return (
                                                                    <p key={jindex}>
                                                                        {file.name}{" "}
                                                                        {"   "}<a
                                                                            href={file.url}
                                                                            download
                                                                            target='_blank'
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                                        </a>
                                                                    </p>
                                                                );
                                                            })}
                                                        </td>
                                                        <td>
                                                            {i.documentsByExporter.map((file, jindex) => {
                                                                return (
                                                                    <p key={jindex}>
                                                                        {file.name}{" "}
                                                                        {"   "}<a
                                                                            href={file.url}
                                                                            download
                                                                            target='_blank'
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                                        </a>
                                                                    </p>
                                                                );
                                                            })}
                                                        </td>
                                                        <td>{i.disputeReason}</td>
                                                        <td>
                                                            {i.disputedDocuments.map((file, jindex) => {
                                                                return (
                                                                    <p key={jindex}>
                                                                        {file.name}{" "}
                                                                        <a
                                                                            href={file.url}
                                                                            download
                                                                            target='_blank'
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                                        </a>
                                                                    </p>
                                                                );
                                                            })}
                                                        </td>
                                                        <td>
                                                            <p><strong>Date: </strong>
                                                                {i.createdAt
                                                                    ? new Date(i.createdAt).toLocaleDateString()
                                                                    : ""}
                                                            </p>
                                                            <p>
                                                                <strong>Time: </strong>
                                                                {i.createdAt
                                                                    ? new Date(i.createdAt).toLocaleTimeString()
                                                                    : ""}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <strong>Date: </strong>
                                                                {i.updatedAt
                                                                    ? new Date(i.updatedAt).toLocaleDateString()
                                                                    : ""}
                                                            </p>
                                                            <p>
                                                                <strong>Time: </strong>
                                                                {i.updatedAt
                                                                    ? new Date(i.updatedAt).toLocaleTimeString()
                                                                    : ""}
                                                            </p>

                                                        </td>
                                                        <td>
                                                            <p><strong>Date: </strong>
                                                                {i.orderTiming
                                                                    ? new Date(i.orderTiming).toLocaleDateString()
                                                                    : ""}
                                                            </p>
                                                            <p>
                                                                <strong>Time: </strong>
                                                                {i.orderTiming
                                                                    ? new Date(i.orderTiming).toLocaleTimeString()
                                                                    : ""}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <strong>Admin Commission: </strong>{i.adminCommission / 10 ** 18} USDT
                                                            </p>
                                                            <p>
                                                                <strong>Contract Price: </strong>{i.numberOfTokens / 10 ** 18} USDT
                                                            </p>
                                                            <p>
                                                                <strong>Total Payable: </strong>{i.adminCommission / 10 ** 18 + i.numberOfTokens / 10 ** 18} USDT
                                                            </p>
                                                        </td>
                                                        <td style={{ textAlign: 'center', alignContent: 'center' }}>{i.adminCommissionPercentage}%</td>
                                                        <td>{i.agreementAddress}</td>

                                                        <td>
                                                            {" "}
                                                            <div className="actions">
                                                                <Button
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setOrder(i);
                                                                        setShow(true);
                                                                    }}
                                                                    className="btn btn-sm bg-warning-light"
                                                                >
                                                                    View Details
                                                                </Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="card-body">
                        {disputedOrders.length === 0 ? (
                            <Card variant="outlined" className="text-center" style={{ padding: "40px", marginTop: '20px', marginBottom: '20px' }}>
                                <Typography variant="body2" className="text-center" color="textSecondary" component="p"  >
                                    <strong>No items to display </strong>
                                </Typography>
                            </Card>
                        ) : (
                            <div className={classes.card}>
                                <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                    justify="flex-start"
                                >
                                    {disputedOrders.map((i, index) => (
                                        <Grid item xs={12} sm={6} md={3} key={index}>
                                            <Card style={{ height: "100%" }} variant="outlined">
                                                <CardContent>
                                                    {i.exporterId !== null ? (
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                            <strong>Exporter:</strong>{i.exporterId.name}
                                                        </Typography>
                                                    ) : (
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                            <strong>Exporter:</strong>
                                                        </Typography>
                                                    )}
                                                    {i.importerId !== null ? (
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                            <strong>Importer:</strong>{i.importerId.name}
                                                        </Typography>
                                                    ) : (
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                            <strong>Importer:</strong>
                                                        </Typography>
                                                    )}

                                                    <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                        <strong>Documents By Importer:</strong>
                                                        <ul>
                                                            {i.documentsByImporter.length === 0 ? (
                                                                <p style={{ color: 'red' }}>No Documents yet</p>
                                                            ) : (
                                                                null
                                                            )}
                                                            {i.documentsByImporter.map((file, jindex) => {
                                                                return (
                                                                    <li key={jindex}>
                                                                        {file.name}{" "}
                                                                        {"   "}<a
                                                                            href={file.url}
                                                                            download
                                                                            target='_blank'
                                                                            rel="noopener noreferrer"
                                                                        // onClick={e => download(e, i.name, i.url)}
                                                                        >
                                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </Typography>
                                                    <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                        <strong>Documents By Exporter:</strong>
                                                        <ul>
                                                            {i.documentsByExporter.length === 0 ? (
                                                                <p style={{ color: 'red' }}>No Documents yet</p>
                                                            ) : (
                                                                null
                                                            )}
                                                            {i.documentsByExporter.map((file, jindex) => {
                                                                return (
                                                                    <li key={jindex}>{" "}
                                                                        {"   "}<a
                                                                            href={file.url}
                                                                            download
                                                                            target='_blank'
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </Typography>
                                                    <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                        <strong>Create At:</strong>
                                                        <ul>
                                                            <li>
                                                                <strong>Date:</strong>
                                                                {i.createdAt
                                                                    ? new Date(i.createdAt).toLocaleDateString()
                                                                    : ""}
                                                            </li>
                                                            <li>
                                                                <strong>Time:</strong>
                                                                {i.createdAt
                                                                    ? new Date(i.createdAt).toLocaleTimeString()
                                                                    : ""}
                                                            </li>
                                                        </ul>

                                                    </Typography>
                                                    <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                        <strong>Updated At: </strong>
                                                        <ul>
                                                            <li>
                                                                <strong>Date:</strong>
                                                                {i.updatedAt
                                                                    ? new Date(i.updatedAt).toLocaleDateString()
                                                                    : ""}
                                                            </li>
                                                            <li>
                                                                <strong>Time:</strong>
                                                                {i.updatedAt
                                                                    ? new Date(i.updatedAt).toLocaleTimeString()
                                                                    : ""}
                                                            </li>
                                                        </ul>
                                                    </Typography>
                                                    <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                        <strong>Contract Time: </strong>
                                                        <ul>
                                                            <li>
                                                                <strong>Date:</strong>
                                                                {i.orderTiming
                                                                    ? new Date(i.orderTiming).toLocaleDateString()
                                                                    : ""}
                                                            </li>
                                                            <li>
                                                                <strong>Time:</strong>
                                                                {i.orderTiming
                                                                    ? new Date(i.orderTiming).toLocaleTimeString()
                                                                    : ""}
                                                            </li>
                                                        </ul>
                                                    </Typography>
                                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                        <strong>Admin Commission: </strong>{i.adminCommission / 10 ** 18} USDT
                                                    </Typography>
                                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                        <strong>Contract Price: </strong>{i.numberOfTokens / 10 ** 18} USDT
                                                    </Typography>
                                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                        <strong>Total Payable: </strong>{i.adminCommission / 10 ** 18 + i.numberOfTokens / 10 ** 18} USDT
                                                    </Typography>
                                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                        <strong>Admin Commission (%): </strong> {i.adminCommissionPercentage}%
                                                    </Typography>
                                                    <Typography style={{ wordWrap: 'break-word' }} className={classes.title} color="textSecondary" gutterBottom>
                                                        <strong>Agreement Address:</strong> {i.agreementAddress}
                                                    </Typography>
                                                    <Typography style={{ wordWrap: 'break-word' }} className={classes.title} color="textSecondary" gutterBottom>
                                                        <strong>Reason Of Dispute:</strong> {i.disputeReason}
                                                    </Typography>
                                                    <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                        <strong>Documents Of Dispute:</strong>
                                                        <ul>
                                                            {i.disputedDocuments.length === 0 ? (
                                                                <p style={{ color: 'red' }}>No Documents yet</p>
                                                            ) : (
                                                                null
                                                            )}
                                                            {i.disputedDocuments.map((file, jindex) => {
                                                                return (
                                                                    <li key={jindex}>{" "}
                                                                        {"   "}<a
                                                                            href={file.url}
                                                                            download
                                                                            target='_blank'
                                                                            rel="noopener noreferrer"
                                                                        // onClick={e => download(e, i.name, i.url)}
                                                                        >
                                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </Typography>                                                </CardContent>


                                                <CardActions>
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setOrder(i);
                                                            setShow(true);
                                                        }}
                                                        className="btn btn-sm btn-block bg-warning-light"
                                                    >
                                                        View Details
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        )}
                    </div>
                )}



            </div>
            <OrderDetailModal
                order={order}
                show={show}
                handleClose={handleClose}
            />
        </div >
    );
}

export default DisputedResolved;
