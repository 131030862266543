import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Link, Redirect } from "react-router-dom";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import loginBanner from "../../../assets/img/importExport.jpg";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import Footer from "../../../components/Footers/Footer";
import Header from "../../../components/Headers/Header";
function LoginScreen(props) {
  let [email, setEmail] = useState();
  let [password, setPassword] = useState();// eslint-disable-next-line
  let [data, setData] = useState("");
  let [isLoading, setIsLoading] = useState(false);// eslint-disable-next-line
  let [isError, setIsError] = useState(false);
  // eslint-disable-next-line
  let [isSuccess, setIsSuccess] = useState(false);// eslint-disable-next-line
  let [mobile, setMobile] = useState("");
  let [KYC, setKYC] = useState(false);

  let [role, setRole] = useState('importer');
  let [msg, setMsg] = useState("");
  // eslint-disable-next-line
  let [isMobileVarified, setIsMobileVarified] = useState(false);
  let handleSubmitEvent = (event) => {
    setMsg("");
    setIsError(false);
    setIsLoading(true);
    event.preventDefault();
    axios
      .post("/api/v1/auth/user/login", {
        email: email.toLowerCase(),
        password: password,
        roles: [role]
      })
      .then((response) => {
        console.log("response", response);
        if (response.data.message === "Confirm Mobile Number First!") {
          setMobile(response.data.Mobile)
          resendCode(response.data.Mobile);
          setIsMobileVarified(true);
          setIsLoading(false);
        } if (response.data.KYCflag === false) {
          localStorage.setItem("Token", response.data.token)
          setKYC(true);
        }
        else if (response.data === "Please verify your email to Login!") {
          setMsg("Please verify your email to Login!");
          setIsLoading(false);
        }
        else if (response.data === "Pending, Please wait for KYC verification!") {
          setMsg("Pending, Please wait for KYC verification!");
          setIsLoading(false);
        } else if (response.data === "User is rejected from admin!") {
          setMsg("Admin Rejected your Account.");
          setIsLoading(false);
        } else {
          if (response.data.role === "participant") {
            setMsg("Individual users must login using Mobile Application.");
          } else {
            Cookies.set("Authorization", response.data.token, {
            });
            setData(response.data.token);
            setIsLoading(false);
            window.location.reload();
          }
        }
      })
      .catch((error) => {
        console.log("error",error);
        console.log("error.response",error.response);
        
        if (error.response !== undefined) {
          setMsg(error.response.data);
        } else {
          setMsg("Unknown Error Occured, try again.");
        }

        setIsError(true);
        setIsLoading(false);
      });
  };
  // eslint-disable-next-line
  let [pinEmail, setPinEmail] = useState();// eslint-disable-next-line
  let [otp, setOtp] = useState("");
  // eslint-disable-next-line
  let handleOtpSubmitEvent = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const userData = {
      verificationcode: otp,
      email: email
    }

    axios
      .post("/api/v1/auth/user/confirmphonenumber", userData)
      .then((response) => {
        console.log("response", response);
        if (response.status === 200)
          setMsg(response.data)
        setIsLoading(false);
        setIsError(false);
        setIsSuccess(true);
        setIsMobileVarified(false)

      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        setMsg(error.data)

        setIsLoading(false);
        setIsError(true);
        setIsSuccess(false);
        setIsMobileVarified(true)
      })
  }
  let resendCode = (Mobile) => {
    const userData = {
      mobile: Mobile,
      email: email
    }
    console.log("clicked");

    axios
      .post("/api/v1/auth/user/resendverificationcode", userData)
      .then((response) => {
        console.log("response", response);
        if (response.status === 200)
          setIsError(false);
        setIsSuccess(true);
        setMsg(response.data);
      })
      .catch(error => {
        console.log(error)
        console.log(error.response);
        setMsg(error.data)
        setIsError(true);
        setIsSuccess(false);
      })
  }
  if (KYC) {
    return <Redirect to='/kyc'></Redirect>
  } else {
    return (

      <div className="account-page" >
        <div className="main-wrapper">
          <div className="home-section home-full-height">
            <Header />
            <div className="content" style={{ paddingTop: "180px", height: "100vh" }} position="absolute">
              <div className="container-fluid">
                <div
                  className="row"
                  style={{ height: `${props.windowHeight}`, marginRight: "px" }}
                >
                  <div className="col-md-8 offset-md-2">
                    {/* <!-- Login Tab Content --> */}
                    <div className="account-content">
                      <div className="row align-items-center justify-content-center">
                        <div
                          className="col-md-8 col-lg-7 login-left"
                          style={{ textAlign: "center" }}
                        >
                          <img
                            src={loginBanner}
                            className="img-fluid"
                            alt="Doccure Login"
                            style={{ height: "400px", paddingTop: "20px" }}
                          />
                        </div>
                        <div className="col-md-11 col-lg-5 login-right">

                          <>
                            <div className="login-header">
                              <h3 style={{ textAlign: "center" }}>Sign In</h3>
                            </div>
                            <form onSubmit={handleSubmitEvent}>
                              <div className="form-group form-focus focused">
                                <input
                                  type="email"
                                  required
                                  className="form-control floating"
                                  defaultValue={email}
                                  onChange={(e) => {
                                    setEmail(e.target.value);
                                  }}
                                />
                                <label className="focus-label">Email</label>
                              </div>
                              <div className="form-group form-focus focused">
                                <input
                                  type="password"
                                  required
                                  className="form-control floating"
                                  defaultValue={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                                <label className="focus-label">Password</label>
                              </div>
                              <FormControl component="fieldset">
                                <label component="legend">Select your Role</label>
                                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                  <FormControlLabel style={{ color: 'black' }} value="importer" onChange={() => {
                                    setRole("importer")
                                  }} checked={role === 'importer'} control={<Radio color="primary" />} label="Importer" />

                                  <FormControlLabel style={{ color: 'black' }} value="exporter" onChange={() => {
                                    setRole("exporter");
                                  }} checked={role === 'exporter'} control={<Radio color="primary" />} label="Exporter" />

                                </RadioGroup>
                              </FormControl>
                              <div className="text-center">
                                <p style={{ color: "red" }}>{msg}</p>
                              </div>
                              <div className="text-right">
                                <Link
                                  to="/forgotPassword"
                                  className="forgot-link"
                                  style={{ color: "#000" }}
                                >
                                  Forgot Password ?
                                </Link>
                              </div>

                              {isLoading ? (
                                <div className="text-center">
                                  <Spinner
                                    animation="border"
                                    role="status"
                                    style={{ color: "#00d0f1" }}
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </Spinner>
                                </div>
                              ) : (
                                <button
                                  // className="btn btn-primary btn-block btn-lg login-btn"
                                  className="btn btn-block btn-lg login-btn"
                                  type="submit"
                                >
                                  Sign In
                                </button>
                              )}

                              {/* Incorrect Email or Password. */}

                              <div className="text-center dont-have">
                                Don’t have an account?{" "}
                                <Link to="/register">Register</Link>
                              </div>
                            </form>
                          </>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Login Tab Content --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer position={""} />
        </div>
      </div >
    );
  }
}

export default LoginScreen;
