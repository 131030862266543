// PersonalDetails.jsx
import React, { Component } from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import 'react-intl-tel-input/dist/main.css';
import { Form } from 'semantic-ui-react';

import logo from "../../../assets/img/img-04.jpg";


class SignupDetails extends Component {
    constructor(props) {
        super(props);
        this.state = { country: '', msg: '', msgM: '', city: '',address:'',factoryName:'', passportPhoto: '', selfiePassportPhoto: '', passportPhotoBlob: logo, selfiePassportPhotoBlob: logo };
        this.mobile = React.createRef();
    }
    componentDidMount = () => {
        const { values } = this.props;
            this.setState({
                country: values.country,
                city: values.city,
                address:values.address,
            })
    }
    selectCountry(val) {
        this.setState({ country: val });
    }

    saveAndContinue = (e) => {
        e.preventDefault();
        if (this.state.city === "") {
            this.setState({ msg: 'Please Enter City' });
            return;
        }
        else if (this.state.country === "") {
            this.setState({ msg: 'Please Select Country' });
            return;
        }
        else if (this.state.address === "") {
            this.setState({ msg: 'Please Enter Address' });
            return;
        }
        else {
            this.props.handleStepTwo({
                city: this.state.city,
                country: this.state.country,
                address: this.state.address,
            })
            this.props.nextStep();
        }
    }

    back = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    render() {
        const { values } = this.props
        const { country } = this.state;

        return (
            <Form >
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="form-group form-focus focused">
                            <input
                                onChange={(e) => {
                                    this.setState({ city: e.target.value })
                                }}
                                defaultValue={values.city}
                                className="form-control floating"
                                required
                            />
                            <label className="focus-label">City</label>

                        </div>
                    </div>
                    <div className="col-12 col-md-12">
                        <div className="form-group ">
                            <CountryDropdown
                                value={country}
                                onChange={(val) => {
                                    this.selectCountry(val);
                                }}
                                className="form-control floating"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-12">
                        <div className="form-group form-focus focused">
                            <input
                                type="text"
                                className="form-control floating"
                                onChange={(e) => {
                                    this.setState({ address: e.target.value })
                                }}
                                defaultValue={values.address}
                                required
                            />
                            <label className="focus-label">Address</label>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                            <p style={{ color: "#FF0000" }}>{this.state.msg}</p>
                        </div>
                
                <br />
                <div className="text-center">
                    <button className="btn btn-lg login-btn" onClick={this.saveAndContinue}>
                        Save And Continue</button>
                </div>
            </Form>
        )
    }
}

export default SignupDetails;