import {
  Card,
  Typography
} from '@material-ui/core/';
import axios from "axios";
import React, { useEffect, useState } from "react";

function TotalUserAccountScreen(props) {
  useEffect(() => {
    getApprovedPractitionerAccounts();
    props.setActiveTab({
      dashboard: "",
      totalUserAccount: "active",
      pendingUserAccount: "",
      disputedOrders: "",
      tradeListOrders: "",
      nonKYCAccount: "",
      earningsList: "",
      referralEarnings: "",
      settings: "",
      changePassword: "",
      resolvedDisputedOrders: "",
    });// eslint-disable-next-line
  }, []);

  let [data, setData] = useState([]);
  let getApprovedPractitionerAccounts = () => {
    axios
      .get(`/api/v1/admin/approvedAccounts`)
      .then((response) => {
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="card">
      <ul className="breadcrumb" style={{ backgroundColor: "#174153" }}>
        <li className="breadcrumb-item">
          <a href="/">Dashboard</a>
        </li>
        <li className="breadcrumb-item active">Total User Accounts</li>
      </ul>

      <div className="card-body">
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="table-responsive" style={{ paddingTop: "20px" }}>
                {data.length === 0 ? (
                  <Card variant="outlined" className="text-center" style={{ padding: "40px", marginTop: '20px', marginBottom: '20px' }}>
                    <Typography variant="body2" className="text-center" color="textSecondary" component="p"  >
                      <strong>No items to display </strong>
                    </Typography>
                  </Card>
                ) : (
                  <table className="table table-hover table-center mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>City</th>
                        <th>Country</th>
                        <th>Address</th>
                        <th>Passport Picture</th>
                        <th>Selfie With Passport</th>
                        <th>Business Name</th>
                        <th>Business Certificate Photo</th>
                        <th>Other Pictures</th>
                        <th>Roles</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((i, index) => (
                        <tr key={i._id}>
                          <td>{index + 1}</td>
                          {/* <td style={{ textAlign: 'center', alignContent: 'center' }}>
                          <a href={ i.selfiePictures[0]}
                            download
                            target='_blank'
                            rel="noopener noreferrer"
                          >
                            <img
                              src={ i.selfiePictures[0]}
                              alt="selfie" className="zoom" width={50} height={50}></img>
                          </a>
                        </td> */}
                          {/* <td></td> */}
                          <td>{i.name}</td>
                          {i.emailConfirmed ? (
                            <td>
                              <i
                                className="fa fa-check-circle verified"
                                aria-hidden="true"
                              ></i>{" "}
                              {i.email}
                            </td>
                          ) : (
                            <td>{i.email}</td>
                          )}
                          <td>{i.mobile}</td>
                          <td>{i.city}</td>
                          <td>{i.country}</td>
                          <td>{i.address}</td>
                          <td style={{ textAlign: 'center', alignContent: 'center' }}>
                            <a href={i.passportPicture}
                              download
                              target='_blank'
                              rel="noopener noreferrer"
                            >
                              <img
                                src={i.passportPicture}
                                alt="passport" className="zoom" width={50} height={50}></img>
                            </a>
                          </td>
                          <td style={{ textAlign: 'center', alignContent: 'center' }}>
                            <a href={i.passportsSelfie[0]}
                              download
                              target='_blank'
                              rel="noopener noreferrer"
                            >
                              <img
                                src={i.passportsSelfie[0]}
                                alt="Passport selfie" className="zoom" width={50} height={50}></img>
                            </a>

                          </td>
                          <td>{i.factories}</td>
                          <td style={{ textAlign: 'center', alignContent: 'center' }}>
                            <a href={i.businessCertificate}
                              download
                              target='_blank'
                              rel="noopener noreferrer"
                            >
                              <img
                                src={i.businessCertificate}
                                alt="Business Certificate" className="zoom" width={50} height={50}></img>
                            </a>
                          </td>
                          <td style={{ textAlign: 'center', alignContent: 'center' }}>
                            {i.picturesOfProducts.map((j, jIndex) => (
                              <a key={jIndex} href={j}
                                download
                                target='_blank'
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={j}
                                  alt="products" style={{ marginRight: '10px' }} className="zoom" width={50} height={50}></img>
                              </a>
                            ))}

                          </td>
                          <td>
                            {i.roles.map((role, index) => (
                              <p key={index}>{role}</p>
                            ))}
                          </td>
                          <td>{i.status}</td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalUserAccountScreen;
