import {
  Card, Typography
} from '@material-ui/core/';
import axios from "axios";
import React, { useEffect, useState } from "react";

function NonKYCAccountScreen(props) {
  useEffect(() => {
    getApprovedPractitionerAccounts();
    props.setActiveTab({
      dashboard: "",
      totalUserAccount: "",
      nonKYCAccount: "active",
      pendingUserAccount: "",
      disputedOrders: "",
      tradeListOrders: "",
      earningsList: "",
      referralEarnings: "",
      settings: "",
      changePassword: "",
      resolvedDisputedOrders: "",
    });// eslint-disable-next-line
  }, []);

  let [data, setData] = useState([]);
  let getApprovedPractitionerAccounts = () => {
    axios
      .get(`/api/v1/admin/newAccounts`)
      .then((response) => {
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="card">
      <ul className="breadcrumb" style={{ backgroundColor: "#174153" }}>
        <li className="breadcrumb-item">
          <a href="/">Dashboard</a>
        </li>
        <li className="breadcrumb-item active">Total Non KYC Accounts</li>
      </ul>

      <div className="card-body">
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="table-responsive" style={{ paddingTop: "40px", paddingBottom: "50px" }}>
                {data.length === 0 ? (
                  <Card variant="outlined" className="text-center" style={{ padding: "40px", marginTop: '20px', marginBottom: '20px' }}>
                    <Typography variant="body2" className="text-center" color="textSecondary" component="p"  >
                      <strong>No items to display </strong>
                    </Typography>
                  </Card>
                ) : (
                  <table className="table table-hover table-center mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        {/* <th>Selfie</th> */}
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>City</th>
                        <th>Country</th>
                        <th>Address</th>
                        <th>Roles</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((i, index) => (
                        <tr key={i._id}>
                          <td>{index + 1}</td>
                          <td>{i.name}</td>
                          {i.emailConfirmed ? (
                            <td>
                              <i
                                className="fa fa-check-circle verified"
                                aria-hidden="true"
                              ></i>{" "}
                              {i.email}
                            </td>
                          ) : (
                            <td>{i.email}</td>
                          )}
                          <td>{i.mobile}</td>
                          <td>{i.city}</td>
                          <td>{i.country}</td>
                          <td>{i.address}</td>
                          <td>
                            {i.roles.map((role, index) => (
                              <p key={index}>{role}</p>
                            ))}
                          </td>
                          <td>{i.status}</td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>

                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NonKYCAccountScreen;
