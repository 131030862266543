import {
    Card
} from '@material-ui/core/';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import ErrorIcon from '@material-ui/icons/Error';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LockIcon from '@material-ui/icons/Lock';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import PropTypes from 'prop-types';
import React from 'react';
import { Spinner } from 'react-bootstrap';

function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function ScrollableTabsButtonForce(props) {
    const classes = useStyles();
    // const [value, setValue] = React.useState(0);
    let jwt = Cookies.get("Authorization");
    let jwtDecoded = jwtDecode(jwt);
    console.log("props", props);

    const handleChange = (event, newValue) => {
        props.setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={props.value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >
                    <Tab label="Pending" icon={
                        <Badge badgeContent={props.pendingOrders.length.toString()} color="primary">
                            <AccessTimeIcon />
                        </Badge>
                    } {...a11yProps(0)} />
                    <Tab label="Accepted" icon={
                        <Badge badgeContent={props.acceptedOrders.length.toString()} color="primary">
                            <ThumbUp />
                        </Badge>
                    } {...a11yProps(1)} />
                    <Tab label="Rejected" icon={
                        <Badge badgeContent={props.rejectedOrders.length.toString()} color="primary">
                            <ThumbDown />
                        </Badge>
                    } {...a11yProps(2)} />
                    <Tab label="Finalized" icon={
                        <Badge badgeContent={props.finalizedOrders.length.toString()} color="primary">
                            <DoneOutlineIcon />
                        </Badge>
                    } {...a11yProps(3)} />
                    <Tab label="Disputed" icon={
                        <Badge badgeContent={props.disputedOrders.length.toString()} color="primary">
                            <ErrorIcon />
                        </Badge>
                    } {...a11yProps(4)} />
                    {jwtDecoded.roles[0] === "importer" ? (
                        <Tab label="Under Delivery" icon={
                            <Badge badgeContent={props.underDeliveryOrders.length.toString()} color="primary">
                                <LocalShippingIcon />
                            </Badge>
                        } {...a11yProps(5)} />
                    ) : (
                        <Tab label="Under Shipment" icon={
                            <Badge badgeContent={props.underShipmentOrders.length.toString()} color="primary">
                                <LocalShippingIcon />
                            </Badge>
                        } {...a11yProps(5)} />
                    )}
                    <Tab label="Completed" icon={
                        <Badge badgeContent={props.completedOrders.length.toString()} color="primary">
                            <DoneAllIcon />
                        </Badge>
                    } {...a11yProps(6)} />
                    {jwtDecoded.roles[0] === "importer" ? (
                        <Tab label="Funds Locked" icon={
                            <Badge badgeContent={props.fundsLockedOrders.length.toString()} color="primary">
                                <LockIcon />
                            </Badge>
                        } {...a11yProps(7)} />
                    ) : (null)}
                </Tabs>
            </AppBar>
            <TabPanel value={props.value} index={0}>
                {props.pendingOrders.length === 0 ? (
                    <Card variant="outlined" className="text-center" style={{ padding: "40px", marginTop: '20px', marginBottom: '20px' }}>
                        <Typography variant="body2" color="textSecondary" component={'span'}  >
                            <strong>No items to display </strong>
                        </Typography>
                    </Card>
                ) : (
                    <span
                        className="table-responsive"
                        style={{ paddingTop: "20px" }}
                    >
                        <table className="table table-hover table-center mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Exporter</th>
                                    <th>Importer</th>
                                    <th>Documents by Importer</th>
                                    <th>Documents by Exporter</th>
                                    <th>Created At</th>
                                    <th>Upadated At</th>
                                    <th>Contract Time</th>
                                    <th style={{ textAlign: 'center', alignContent: 'center' }}>Price</th>
                                    <th>Admin Commission (%)</th>

                                    <th style={{ textAlign: 'center' }} >Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.pendingOrders.map((i, index) => (
                                    <tr key={i._id}>
                                        <td>{index + 1}</td>
                                        <td>{i.exporterId.name}</td>
                                        <td>{i.importerId.name}</td>
                                        <td>
                                            {i.documentsByImporter.map((file, jindex) => {
                                                return (
                                                    <p key={jindex}>
                                                        {file.name}{" "}
                                                        {"   "} <a
                                                            href={file.url}
                                                            download
                                                            target='_blank'
                                                            rel="noopener noreferrer"

                                                        >
                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                        </a>
                                                    </p>
                                                );
                                            })}
                                        </td>
                                        <td>
                                            {i.documentsByExporter.map((file, jindex) => {
                                                return (
                                                    <p key={jindex}>
                                                        {file.name}{" "}
                                                        {"   "} <a
                                                            href={file.url}
                                                            download
                                                            target='_blank'
                                                            rel="noopener noreferrer"

                                                        >
                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                        </a>
                                                    </p>
                                                );
                                            })}
                                        </td>
                                        <td>
                                            <p><strong>Date: </strong>
                                                {i.createdAt
                                                    ? new Date(i.createdAt).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.createdAt
                                                    ? new Date(i.createdAt).toLocaleTimeString()
                                                    : ""}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                <strong>Date: </strong>
                                                {i.updatedAt
                                                    ? new Date(i.updatedAt).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.updatedAt
                                                    ? new Date(i.updatedAt).toLocaleTimeString()
                                                    : ""}
                                            </p>

                                        </td>
                                        <td>
                                            <p><strong>Date: </strong>
                                                {i.orderTiming
                                                    ? new Date(i.orderTiming).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.orderTiming
                                                    ? new Date(i.orderTiming).toLocaleTimeString()
                                                    : ""}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                <strong>Admin Commission: </strong>{i.adminCommission / 10 ** 18} USDT
                                            </p>
                                            <p>
                                                <strong>Contract Price: </strong>{i.numberOfTokens / 10 ** 18} USDT
                                            </p>
                                            <p>
                                                <strong>Total Payable: </strong>{i.adminCommission / 10 ** 18 + i.numberOfTokens / 10 ** 18} USDT
                                            </p>
                                        </td>
                                        <td style={{ textAlign: 'center', alignContent: 'center' }}>{i.adminCommissionPercentage}%</td>

                                        <td>
                                            {" "}
                                            {
                                                <span className="actions">
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            props.setOrder(i);
                                                            props.setShow(true);
                                                        }}
                                                        className="btn btn-sm btn-block bg-warning-light"
                                                    >

                                                        view details
                                                    </Button>
                                                </span>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </span>
                )}

            </TabPanel>
            <TabPanel value={props.value} index={1}>
                {props.acceptedOrders.length === 0 ? (
                    <Card variant="outlined" className="text-center" style={{ padding: "40px", marginTop: '20px', marginBottom: '20px' }}>
                        <Typography variant="body2" color="textSecondary" component={'span'}  >
                            <strong>No items to display </strong>
                        </Typography>
                    </Card>
                ) : (
                    <span
                        className="table-responsive"
                        style={{ paddingTop: "20px" }}
                    >
                        <table className="table table-hover table-center mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Exporter</th>
                                    <th>Importer</th>
                                    <th>Documents by Importer</th>
                                    <th>Documents by Exporter</th>
                                    <th>Created At</th>
                                    <th>Upadated At</th>
                                    <th>Contract Time</th>
                                    <th style={{ textAlign: 'center', alignContent: 'center' }}>Price</th>
                                    <th>Admin Commission (%)</th>
                                    <th style={{ textAlign: 'center' }} >Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.acceptedOrders.map((i, index) => (
                                    <tr key={i._id}>
                                        <td>{index + 1}</td>
                                        <td>{i.exporterId.name}</td>
                                        <td>{i.importerId.name}</td>
                                        <td>
                                            {i.documentsByImporter.map((file, jindex) => {
                                                return (
                                                    <p key={jindex}>
                                                        {file.name}{" "}
                                                        {"   "} <a
                                                            href={file.url}
                                                            download
                                                            target='_blank'
                                                            rel="noopener noreferrer"

                                                        >
                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                        </a>
                                                    </p>
                                                );
                                            })}
                                            {jwtDecoded.roles[0] === "importer" ? (
                                                <span className="actions" style={{ marginTop: '10px' }}>
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            props.setOrder(i);
                                                            props.handleShowUploadModal()
                                                        }}
                                                        className="btn btn-sm btn-block bg-warning-light"
                                                        block
                                                    >
                                                        <i className="fa fa-upload" aria-hidden="true"></i>
                                                        Upload More Documents
                                                    </Button>
                                                </span>
                                            ) : (null)}
                                        </td>
                                        <td>
                                            {i.documentsByExporter.map((file, jindex) => {
                                                return (
                                                    <p key={jindex}>
                                                        {file.name}{" "}
                                                        {"   "} <a
                                                            href={file.url}
                                                            download
                                                            target='_blank'
                                                            rel="noopener noreferrer"

                                                        >
                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                        </a>
                                                    </p>
                                                );
                                            })}
                                            {jwtDecoded.roles[0] === "exporter" ? (
                                                <span className="actions" style={{ marginTop: '10px' }}>
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            props.setOrder(i);
                                                            props.handleShowUploadModal()
                                                        }}
                                                        className="btn btn-sm btn-block bg-warning-light"
                                                        block
                                                    >
                                                        <i className="fa fa-upload" aria-hidden="true"></i>
                                                        Upload More Documents
                                                    </Button>
                                                </span>
                                            ) : (null)}
                                        </td>
                                        <td>
                                            <p><strong>Date: </strong>
                                                {i.createdAt
                                                    ? new Date(i.createdAt).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.createdAt
                                                    ? new Date(i.createdAt).toLocaleTimeString()
                                                    : ""}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                <strong>Date: </strong>
                                                {i.updatedAt
                                                    ? new Date(i.updatedAt).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.updatedAt
                                                    ? new Date(i.updatedAt).toLocaleTimeString()
                                                    : ""}
                                            </p>

                                        </td>
                                        <td>
                                            <p><strong>Date: </strong>
                                                {i.orderTiming
                                                    ? new Date(i.orderTiming).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.orderTiming
                                                    ? new Date(i.orderTiming).toLocaleTimeString()
                                                    : ""}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                <strong>Admin Commission: </strong>{i.adminCommission / 10 ** 18} USDT
                                            </p>
                                            <p>
                                                <strong>Contract Price: </strong>{i.numberOfTokens / 10 ** 18} USDT
                                            </p>
                                            <p>
                                                <strong>Total Payable: </strong>{i.adminCommission / 10 ** 18 + i.numberOfTokens / 10 ** 18} USDT
                                            </p>
                                        </td>
                                        <td style={{ textAlign: 'center', alignContent: 'center' }}>{i.adminCommissionPercentage}%</td>

                                        <td>
                                            {" "}
                                            {
                                                <span className="actions">
                                                    {(jwtDecoded.roles[0] === "importer" && i.documentsByExporter.length === 0) || (jwtDecoded.roles[0] === "exporter" && i.documentsByImporter.length === 0) ? (
                                                        <Button
                                                            disabled
                                                            className="btn btn-sm bg-info-light"
                                                        >
                                                            Accept/Reject Documents
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                props.setOrder(i);
                                                                props.handleShowAcceptRejectModal();
                                                            }}
                                                            className="btn btn-sm bg-info-light"
                                                        >
                                                            Accept/Reject Documents
                                                        </Button>
                                                    )}
                                                    {' '}
                                                    {/* {jwtDecoded.roles[0] === "importer" ? (
                                                        props.allowanceAgainstEscrow < (i.adminCommission / 1 + i.numberOfTokens / 1) ? (

                                                            console.log("props.allowanceAgainstEscrow", props.allowanceAgainstEscrow, i.adminCommission / 1 + i.numberOfTokens / 1) &&

                                                                props.isApprovingFunds == true ? (
                                                                // console.log("isApprovingFunds", props.isApprovingFunds)
                                                                <Spinner
                                                                    animation="border"
                                                                    role="status"
                                                                    className="bg-success-light"
                                                                >
                                                                    <span className="sr-only">Loading...</span>
                                                                </Spinner>
                                                            ) : (
                                                                <Button
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        // props.setOrder();
                                                                        props.approveFunds(i);
                                                                    }}
                                                                    className="btn btn-sm bg-success-light"
                                                                >
                                                                    Approve Funds
                                                                </Button>
                                                            )

                                                        ) : ( */}
                                                    {/* <Button
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    props.setOrder(i);
                                                                    props.handleShowFinalizeModal();
                                                                }}
                                                                className="btn btn-sm bg-success-light"
                                                            >
                                                                Finalize Order
                                                            </Button>
                                                        )

                                                    ) : ( */}
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            props.setOrder(i);
                                                            props.handleShowFinalizeModal();
                                                        }}
                                                        className="btn btn-sm bg-success-light"
                                                    >
                                                        Finalize Order
                                                    </Button>
                                                    {/* )} */}


                                                </span>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </span>
                )}
            </TabPanel>
            <TabPanel value={props.value} index={2}>
                {props.rejectedOrders.length === 0 ? (
                    <Card variant="outlined" className="text-center" style={{ padding: "40px", marginTop: '20px', marginBottom: '20px' }}>
                        <Typography variant="body2" color="textSecondary" component={'span'}  >
                            <strong>No items to display </strong>
                        </Typography>
                    </Card>
                ) : (
                    <span
                        className="table-responsive"
                        style={{ paddingTop: "20px" }}
                    >
                        <table className="table table-hover table-center mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Exporter</th>
                                    <th>Importer</th>
                                    <th>Documents by Importer</th>
                                    <th>Documents by Exporter</th>
                                    <th>Created At</th>
                                    <th>Upadated At</th>
                                    <th>Contract Time</th>
                                    <th style={{ textAlign: 'center', alignContent: 'center' }}>Price</th>
                                    <th>Admin Commission (%)</th>
                                    <th style={{ textAlign: 'center' }} >Actions</th>

                                    {/* <th className="text-right">Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {props.rejectedOrders.map((i, index) => (
                                    <tr key={i._id}>
                                        <td>{index + 1}</td>
                                        <td>{i.exporterId.name}</td>
                                        <td>{i.importerId.name}</td>
                                        <td>
                                            {i.documentsByImporter.map((file, jindex) => {
                                                return (
                                                    <p key={jindex}>
                                                        {file.name}{" "}
                                                        {"   "} <a
                                                            href={file.url}
                                                            download
                                                            target='_blank'
                                                            rel="noopener noreferrer"

                                                        >
                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                        </a>
                                                    </p>
                                                );
                                            })}
                                        </td>
                                        <td>
                                            {i.documentsByExporter.map((file, jindex) => {
                                                return (
                                                    <p key={jindex}>
                                                        {file.name}{" "}
                                                        {"   "} <a
                                                            href={file.url}
                                                            download
                                                            target='_blank'
                                                            rel="noopener noreferrer"

                                                        >
                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                        </a>
                                                    </p>
                                                );
                                            })}
                                        </td>
                                        <td>
                                            <p><strong>Date: </strong>
                                                {i.createdAt
                                                    ? new Date(i.createdAt).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.createdAt
                                                    ? new Date(i.createdAt).toLocaleTimeString()
                                                    : ""}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                <strong>Date: </strong>
                                                {i.updatedAt
                                                    ? new Date(i.updatedAt).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.updatedAt
                                                    ? new Date(i.updatedAt).toLocaleTimeString()
                                                    : ""}
                                            </p>

                                        </td>
                                        <td>
                                            <p><strong>Date: </strong>
                                                {i.orderTiming
                                                    ? new Date(i.orderTiming).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.orderTiming
                                                    ? new Date(i.orderTiming).toLocaleTimeString()
                                                    : ""}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                <strong>Admin Commission: </strong>{i.adminCommission / 10 ** 18} USDT
                                            </p>
                                            <p>
                                                <strong>Contract Price: </strong>{i.numberOfTokens / 10 ** 18} USDT
                                            </p>
                                            <p>
                                                <strong>Total Payable: </strong>{i.adminCommission / 10 ** 18 + i.numberOfTokens / 10 ** 18} USDT
                                            </p>
                                        </td>
                                        <td style={{ textAlign: 'center', alignContent: 'center' }}>{i.adminCommissionPercentage}%</td>


                                        <td>
                                            {" "}
                                            {
                                                <span className="actions">
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            props.setOrder(i);
                                                            props.setShow(true);
                                                        }}
                                                        className="btn btn-sm btn-block bg-warning-light"
                                                    >

                                                        view details
                                                    </Button>
                                                </span>
                                            }
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </span>
                )}

            </TabPanel>
            <TabPanel value={props.value} index={3}>
                {props.finalizedOrders.length === 0 ? (
                    <Card variant="outlined" className="text-center" style={{ padding: "40px", marginTop: '20px', marginBottom: '20px' }}>
                        <Typography variant="body2" color="textSecondary" component={'span'}  >
                            <strong>No items to display </strong>
                        </Typography>
                    </Card>
                ) : (
                    <span
                        className="table-responsive"
                        style={{ paddingTop: "20px" }}
                    >
                        <table className="table table-hover table-center mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Exporter</th>
                                    <th>Importer</th>
                                    <th>Documents by Importer</th>
                                    <th>Documents by Exporter</th>
                                    <th>Created At</th>
                                    <th>Upadated At</th>
                                    <th>Contract Time</th>
                                    <th style={{ textAlign: 'center', alignContent: 'center' }}>Price</th>
                                    <th>Admin Commission (%)</th>
                                    {jwtDecoded.roles[0] === "importer" ? (
                                        <th>Agreement Address</th>
                                    ) : (null)}
                                    <th style={{ textAlign: 'center' }} >Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.finalizedOrders.map((i, index) => (
                                    <tr key={i._id}>
                                        <td>{index + 1}</td>
                                        <td>{i.exporterId.name}</td>
                                        <td>{i.importerId.name}</td>
                                        <td>
                                            {i.documentsByImporter.map((file, jindex) => {
                                                return (
                                                    <p key={jindex}>
                                                        {file.name}{" "}
                                                        {"   "} <a
                                                            href={file.url}
                                                            download
                                                            target='_blank'
                                                            rel="noopener noreferrer"

                                                        >
                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                        </a>
                                                    </p>
                                                );
                                            })}
                                        </td>
                                        <td>
                                            {i.documentsByExporter.map((file, jindex) => {
                                                return (
                                                    <p>
                                                        {file.name}{" "}
                                                        {"   "} <a
                                                            href={file.url}
                                                            download
                                                            target='_blank'
                                                            rel="noopener noreferrer"

                                                        >
                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                        </a>
                                                    </p>
                                                );
                                            })}
                                        </td>
                                        <td>
                                            <p><strong>Date: </strong>
                                                {i.createdAt
                                                    ? new Date(i.createdAt).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.createdAt
                                                    ? new Date(i.createdAt).toLocaleTimeString()
                                                    : ""}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                <strong>Date: </strong>
                                                {i.updatedAt
                                                    ? new Date(i.updatedAt).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.updatedAt
                                                    ? new Date(i.updatedAt).toLocaleTimeString()
                                                    : ""}
                                            </p>

                                        </td>
                                        <td>
                                            <p><strong>Date: </strong>
                                                {i.orderTiming
                                                    ? new Date(i.orderTiming).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.orderTiming
                                                    ? new Date(i.orderTiming).toLocaleTimeString()
                                                    : ""}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                <strong>Admin Commission: </strong>{i.adminCommission / 10 ** 18} USDT
                                            </p>
                                            <p>
                                                <strong>Contract Price: </strong>{i.numberOfTokens / 10 ** 18} USDT
                                            </p>
                                            <p>
                                                <strong>Total Payable: </strong>{i.adminCommission / 10 ** 18 + i.numberOfTokens / 10 ** 18} USDT
                                            </p>
                                        </td>
                                        <td style={{ textAlign: 'center', alignContent: 'center' }}>{i.adminCommissionPercentage}%</td>

                                        {jwtDecoded.roles[0] === "importer" ? (
                                            i.agreementAddress !== undefined ? (
                                                <td >{i.agreementAddress}</td>
                                            ) : (
                                                <td>Waiting for Exporter to Finalize</td>
                                            )

                                        ) : (null)}

                                        <td>
                                            {" "}
                                            <span className="actions">
                                                <Button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        props.setOrder(i);
                                                        props.setShow(true);
                                                    }}
                                                    className="btn btn-sm bg-warning-light"
                                                >

                                                    view details
                                                </Button>{' '}
                                                {jwtDecoded.roles[0] === "importer" ? (
                                                    props.allowanceAgainstEscrow < (i.adminCommission / 1 + i.numberOfTokens / 1) ? (

                                                        // console.log("props.allowanceAgainstEscrow", props.allowanceAgainstEscrow, i.adminCommission / 1 + i.numberOfTokens / 1) &&

                                                        props.isApprovingFunds ? (
                                                            // console.log("isApprovingFunds", props.isApprovingFunds)
                                                            <Spinner
                                                                animation="border"
                                                                role="status"
                                                                className="bg-success-light"
                                                            >
                                                                <span className="sr-only">Loading...</span>
                                                            </Spinner>
                                                        ) : (
                                                            <Button
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    // props.setOrder();
                                                                    props.approveFunds(i);
                                                                }}
                                                                className="btn btn-sm bg-success-light"
                                                            >
                                                                Approve Funds
                                                            </Button>
                                                        )
                                                    ) : (
                                                        i.isExporterStatusFinalized && i.agreementAddress !== undefined ? (
                                                            <Button
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    props.setOrder(i);
                                                                    props.handleShowLockFundsModal();
                                                                }}
                                                                className="btn btn-sm bg-danger-light "
                                                            >
                                                                <i className="fe fe-lock"></i>
                                                                Lock Funds
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    console.log("HELLO");
                                                                    // props.setOrder(i);
                                                                    // props.setShow(true);
                                                                }}
                                                                disabled
                                                                className="btn btn-sm bg-danger-light not-allowed"
                                                            >
                                                                <i className="fe fe-lock"></i>
                                                                Lock Funds
                                                            </Button>
                                                        )
                                                    )

                                                ) : (
                                                    i.isImporterStatusFundsLocked ? (
                                                        <Button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                props.setOrder(i);
                                                                props.handleShowCreateShipmentModal();
                                                            }}
                                                            className="btn btn-sm bg-success-light"
                                                        >
                                                            <i className="fe fe-truck"></i>
                                                            Create Shipment
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                            disabled
                                                            className="btn btn-sm bg-success-light not-allowed"
                                                        >
                                                            <i className="fe fe-lock"></i>
                                                            Create Shipment
                                                        </Button>
                                                    )

                                                )}
                                            </span>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </span>
                )}
            </TabPanel>
            <TabPanel value={props.value} index={4}>
                {props.disputedOrders.length === 0 ? (
                    <Card variant="outlined" className="text-center" style={{ padding: "40px", marginTop: '20px', marginBottom: '20px' }}>
                        <Typography variant="body2" color="textSecondary" component={'span'}  >
                            <strong>No items to display </strong>
                        </Typography>
                    </Card>
                ) : (
                    <span
                        className="table-responsive"
                        style={{ paddingTop: "20px" }}
                    >
                        <table className="table table-hover table-center mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Exporter</th>
                                    <th>Importer</th>
                                    <th>Documents by Importer</th>
                                    <th>Documents by Exporter</th>
                                    <th>Created At</th>
                                    <th>Upadated At</th>
                                    <th>Contract Time</th>
                                    <th style={{ textAlign: 'center', alignContent: 'center' }}>Price</th>
                                    <th>Admin Commission (%)</th>

                                    <th style={{ textAlign: 'center' }} >Actions</th>

                                    {/* <th className="text-right">Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {props.disputedOrders.map((i, index) => (
                                    <tr key={i._id}>
                                        <td>{index + 1}</td>
                                        <td>{i.exporterId.name}</td>
                                        <td>{i.importerId.name}</td>
                                        <td>
                                            {i.documentsByImporter.map((file, jindex) => {
                                                return (
                                                    <p key={jindex}>
                                                        {file.name}{" "}
                                                        {"   "} <a
                                                            href={file.url}
                                                            download
                                                            target='_blank'
                                                            rel="noopener noreferrer"
                                                        >
                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                        </a>
                                                    </p>
                                                );
                                            })}
                                        </td>
                                        <td>
                                            {i.documentsByExporter.map((file, jindex) => {
                                                return (
                                                    <p key={jindex}>
                                                        {file.name}{" "}
                                                        {"   "} <a
                                                            href={file.url}
                                                            download
                                                            target='_blank'
                                                            rel="noopener noreferrer"

                                                        >
                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                        </a>
                                                    </p>
                                                );
                                            })}
                                        </td>
                                        <td>
                                            <p><strong>Date: </strong>
                                                {i.createdAt
                                                    ? new Date(i.createdAt).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.createdAt
                                                    ? new Date(i.createdAt).toLocaleTimeString()
                                                    : ""}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                <strong>Date: </strong>
                                                {i.updatedAt
                                                    ? new Date(i.updatedAt).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.updatedAt
                                                    ? new Date(i.updatedAt).toLocaleTimeString()
                                                    : ""}
                                            </p>

                                        </td>
                                        <td>
                                            <p><strong>Date: </strong>
                                                {i.orderTiming
                                                    ? new Date(i.orderTiming).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.orderTiming
                                                    ? new Date(i.orderTiming).toLocaleTimeString()
                                                    : ""}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                <strong>Admin Commission: </strong>{i.adminCommission / 10 ** 18} USDT
                                            </p>
                                            <p>
                                                <strong>Contract Price: </strong>{i.numberOfTokens / 10 ** 18} USDT
                                            </p>
                                            <p>
                                                <strong>Total Payable: </strong>{i.adminCommission / 10 ** 18 + i.numberOfTokens / 10 ** 18} USDT
                                            </p>
                                        </td>
                                        <td style={{ textAlign: 'center', alignContent: 'center' }}>{i.adminCommissionPercentage}%</td>


                                        <td>
                                            {" "}
                                            {
                                                <span className="actions">
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            props.setOrder(i);
                                                            props.setShow(true);
                                                        }}
                                                        className="btn btn-sm btn-block bg-warning-light"
                                                    >

                                                        view details
                                                    </Button>
                                                </span>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </span>
                )}
            </TabPanel>
            <TabPanel value={props.value} index={5}>
                {jwtDecoded.roles[0] === "importer" ? (
                    props.underDeliveryOrders.length === 0 ? (
                        <Card variant="outlined" className="text-center" style={{ padding: "40px", marginTop: '20px', marginBottom: '20px' }}>
                            <Typography variant="body2" color="textSecondary" component={'span'}  >
                                <strong>No items to display </strong>
                            </Typography>
                        </Card>
                    ) : (
                        <span
                            className="table-responsive"
                            style={{ paddingTop: "20px" }}
                        >
                            <table className="table table-hover table-center mb-0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Exporter</th>
                                        <th>Importer</th>
                                        <th>Documents by Importer</th>
                                        <th>Documents by Exporter</th>
                                        <th>Created At</th>
                                        <th>Upadated At</th>
                                        <th>Contract Time</th>
                                        <th style={{ textAlign: 'center', alignContent: 'center' }}>Price</th>
                                        <th>Admin Commission (%)</th>
                                        <th>Agreement Address</th>
                                        <th style={{ textAlign: 'center' }} >Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.underDeliveryOrders.map((i, index) => (
                                        <tr key={i._id}>
                                            <td>{index + 1}</td>
                                            <td>{i.exporterId.name}</td>
                                            <td>{i.importerId !== null ? (i.importerId.name) : (null)}</td>
                                            <td>
                                                {i.documentsByImporter.map((file, jindex) => {
                                                    return (
                                                        <p key={jindex}>
                                                            {file.name}{" "}
                                                            {"   "} <a
                                                                href={file.url}
                                                                download
                                                                target='_blank'
                                                                rel="noopener noreferrer"

                                                            >
                                                                <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                            </a>
                                                        </p>
                                                    );
                                                })}
                                            </td>
                                            <td>
                                                {i.documentsByExporter.map((file, jindex) => {
                                                    return (
                                                        <p key={jindex}>
                                                            {file.name}{" "}
                                                            {"   "} <a
                                                                href={file.url}
                                                                download
                                                                target='_blank'
                                                                rel="noopener noreferrer"

                                                            >
                                                                <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                            </a>
                                                        </p>
                                                    );
                                                })}
                                            </td>
                                            <td>
                                                <p><strong>Date: </strong>
                                                    {i.createdAt
                                                        ? new Date(i.createdAt).toLocaleDateString()
                                                        : ""}
                                                </p>
                                                <p>
                                                    <strong>Time: </strong>
                                                    {i.createdAt
                                                        ? new Date(i.createdAt).toLocaleTimeString()
                                                        : ""}
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <strong>Date: </strong>
                                                    {i.updatedAt
                                                        ? new Date(i.updatedAt).toLocaleDateString()
                                                        : ""}
                                                </p>
                                                <p>
                                                    <strong>Time: </strong>
                                                    {i.updatedAt
                                                        ? new Date(i.updatedAt).toLocaleTimeString()
                                                        : ""}
                                                </p>

                                            </td>
                                            <td>
                                                <p><strong>Date: </strong>
                                                    {i.orderTiming
                                                        ? new Date(i.orderTiming).toLocaleDateString()
                                                        : ""}
                                                </p>
                                                <p>
                                                    <strong>Time: </strong>
                                                    {i.orderTiming
                                                        ? new Date(i.orderTiming).toLocaleTimeString()
                                                        : ""}
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <strong>Admin Commission: </strong>{i.adminCommission / 10 ** 18} USDT
                                                </p>
                                                <p>
                                                    <strong>Contract Price: </strong>{i.numberOfTokens / 10 ** 18} USDT
                                                </p>
                                                <p>
                                                    <strong>Total Payable: </strong>{i.adminCommission / 10 ** 18 + i.numberOfTokens / 10 ** 18} USDT
                                                </p>
                                            </td>
                                            <td style={{ textAlign: 'center', alignContent: 'center' }}>{i.adminCommissionPercentage}%</td>

                                            <td>{i.agreementAddress}</td>
                                            <td>
                                                {" "}
                                                {
                                                    <span className="actions">
                                                        <Button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                props.setOrder(i);
                                                                props.setShow(true);
                                                            }}
                                                            className="btn btn-sm bg-warning-light"
                                                        >

                                                            view details
                                                        </Button>{' '}
                                                        <Button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                props.setOrder(i);
                                                                props.handleShowDisputeOrderModal();
                                                            }}
                                                            className="btn btn-sm bg-danger-light"
                                                        >
                                                            Dispute Order
                                                        </Button>{' '}
                                                        <Button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                props.setOrder(i);
                                                                props.handleShowReceivedAndReleaseModal();
                                                            }}
                                                            className="btn btn-sm bg-success-light"
                                                        >
                                                            Received and Release Funds
                                                        </Button>
                                                    </span>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </span>
                    )) : (
                    props.underShipmentOrders.length === 0 ? (
                        <Card variant="outlined" className="text-center" style={{ padding: "40px", marginTop: '20px', marginBottom: '20px' }}>
                            <Typography variant="body2" color="textSecondary" component={'span'}  >
                                <strong>No items to display </strong>
                            </Typography>
                        </Card>
                    ) : (
                        <span
                            className="table-responsive"
                            style={{ paddingTop: "20px" }}
                        >
                            <table className="table table-hover table-center mb-0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Exporter</th>
                                        <th>Importer</th>
                                        <th>Documents by Importer</th>
                                        <th>Documents by Exporter</th>
                                        <th>Created At</th>
                                        <th>Upadated At</th>
                                        <th>Contract Time</th>
                                        <th style={{ textAlign: 'center', alignContent: 'center' }}>Price</th>
                                        <th>Admin Commission (%)</th>

                                        <th style={{ textAlign: 'center' }} >Actions</th>

                                        {/* <th className="text-right">Actions</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.underShipmentOrders.map((i, index) => (
                                        <tr key={i._id}>
                                            <td>{index + 1}</td>
                                            <td>{i.exporterId.name}</td>
                                            <td>{i.importerId !== null ? (i.importerId.name) : (null)}</td>
                                            <td>
                                                {i.documentsByImporter.map((file, jindex) => {
                                                    return (
                                                        <p key={jindex}>
                                                            {file.name}{" "}
                                                            {"   "} <a
                                                                href={file.url}
                                                                download
                                                                target='_blank'
                                                                rel="noopener noreferrer"

                                                            >
                                                                <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                            </a>
                                                        </p>
                                                    );
                                                })}
                                            </td>
                                            <td>
                                                {i.documentsByExporter.map((file, jindex) => {
                                                    return (
                                                        <p key={jindex}>
                                                            {file.name}{" "}
                                                            {"   "} <a
                                                                href={file.url}
                                                                download
                                                                target='_blank'
                                                                rel="noopener noreferrer"

                                                            >
                                                                <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                            </a>
                                                        </p>
                                                    );
                                                })}
                                            </td>
                                            <td>
                                                <p><strong>Date: </strong>
                                                    {i.createdAt
                                                        ? new Date(i.createdAt).toLocaleDateString()
                                                        : ""}
                                                </p>
                                                <p>
                                                    <strong>Time: </strong>
                                                    {i.createdAt
                                                        ? new Date(i.createdAt).toLocaleTimeString()
                                                        : ""}
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <strong>Date: </strong>
                                                    {i.updatedAt
                                                        ? new Date(i.updatedAt).toLocaleDateString()
                                                        : ""}
                                                </p>
                                                <p>
                                                    <strong>Time: </strong>
                                                    {i.updatedAt
                                                        ? new Date(i.updatedAt).toLocaleTimeString()
                                                        : ""}
                                                </p>

                                            </td>
                                            <td>
                                                <p><strong>Date: </strong>
                                                    {i.orderTiming
                                                        ? new Date(i.orderTiming).toLocaleDateString()
                                                        : ""}
                                                </p>
                                                <p>
                                                    <strong>Time: </strong>
                                                    {i.orderTiming
                                                        ? new Date(i.orderTiming).toLocaleTimeString()
                                                        : ""}
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <strong>Admin Commission: </strong>{i.adminCommission / 10 ** 18} USDT
                                                </p>
                                                <p>
                                                    <strong>Contract Price: </strong>{i.numberOfTokens / 10 ** 18} USDT
                                                </p>
                                                <p>
                                                    <strong>Total Payable: </strong>{i.adminCommission / 10 ** 18 + i.numberOfTokens / 10 ** 18} USDT
                                                </p>
                                            </td>
                                            <td style={{ textAlign: 'center', alignContent: 'center' }}>{i.adminCommissionPercentage}%</td>


                                            <td>
                                                {" "}
                                                {
                                                    <span className="actions">
                                                        <Button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                props.setOrder(i);
                                                                props.setShow(true);
                                                            }}
                                                            className="btn btn-sm btn-block bg-warning-light"
                                                        >

                                                            view details
                                                        </Button>
                                                    </span>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </span>
                    )
                )}
            </TabPanel>
            <TabPanel value={props.value} index={6}>
                {props.completedOrders.length === 0 ? (
                    <Card variant="outlined" className="text-center" style={{ padding: "40px", marginTop: '20px', marginBottom: '20px' }}>
                        <Typography variant="body2" color="textSecondary" component={'span'}  >
                            <strong>No items to display </strong>
                        </Typography>
                    </Card>
                ) : (
                    <span
                        className="table-responsive"
                        style={{ paddingTop: "20px" }}
                    >
                        <table className="table table-hover table-center mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Exporter</th>
                                    <th>Importer</th>
                                    <th>Documents by Importer</th>
                                    <th>Documents by Exporter</th>
                                    <th>Created At</th>
                                    <th>Upadated At</th>
                                    <th>Contract Time</th>
                                    <th style={{ textAlign: 'center', alignContent: 'center' }}>Price</th>
                                    <th>Admin Commission (%)</th>
                                    <th style={{ textAlign: 'center' }} >Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.completedOrders.map((i, index) => (
                                    <tr key={i._id}>
                                        <td>{index + 1}</td>
                                        <td>{i.exporterId.name}</td>
                                        <td>{i.importerId.name}</td>
                                        <td>
                                            {i.documentsByImporter.map((file, jindex) => {
                                                return (
                                                    <p key={jindex}>
                                                        {file.name}{" "}
                                                        {"   "} <a
                                                            href={file.url}
                                                            download
                                                            target='_blank'
                                                            rel="noopener noreferrer"

                                                        >
                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                        </a>
                                                    </p>
                                                );
                                            })}
                                        </td>
                                        <td>
                                            {i.documentsByExporter.map((file, jindex) => {
                                                return (
                                                    <p key={jindex}>
                                                        {file.name}{" "}
                                                        {"   "} <a
                                                            href={file.url}
                                                            download
                                                            target='_blank'
                                                            rel="noopener noreferrer"

                                                        >
                                                            <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                        </a>
                                                    </p>
                                                );
                                            })}
                                        </td>
                                        <td>
                                            <p><strong>Date: </strong>
                                                {i.createdAt
                                                    ? new Date(i.createdAt).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.createdAt
                                                    ? new Date(i.createdAt).toLocaleTimeString()
                                                    : ""}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                <strong>Date: </strong>
                                                {i.updatedAt
                                                    ? new Date(i.updatedAt).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.updatedAt
                                                    ? new Date(i.updatedAt).toLocaleTimeString()
                                                    : ""}
                                            </p>

                                        </td>
                                        <td>
                                            <p><strong>Date: </strong>
                                                {i.orderTiming
                                                    ? new Date(i.orderTiming).toLocaleDateString()
                                                    : ""}
                                            </p>
                                            <p>
                                                <strong>Time: </strong>
                                                {i.orderTiming
                                                    ? new Date(i.orderTiming).toLocaleTimeString()
                                                    : ""}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                <strong>Admin Commission: </strong>{i.adminCommission / 10 ** 18} USDT
                                            </p>
                                            <p>
                                                <strong>Contract Price: </strong>{i.numberOfTokens / 10 ** 18} USDT
                                            </p>
                                            <p>
                                                <strong>Total Payable: </strong>{i.adminCommission / 10 ** 18 + i.numberOfTokens / 10 ** 18} USDT
                                            </p>
                                        </td>
                                        <td style={{ textAlign: 'center', alignContent: 'center' }}>{i.adminCommissionPercentage}%</td>


                                        <td>
                                            {" "}
                                            {
                                                <span className="actions">
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            props.setOrder(i);
                                                            props.setShow(true);
                                                        }}
                                                        className="btn btn-sm btn-block bg-warning-light"
                                                    >

                                                        view details
                                                    </Button>
                                                </span>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </span>
                )}
            </TabPanel>
            <TabPanel value={props.value} index={7}>
                {jwtDecoded.roles[0] === "importer" ? (
                    props.fundsLockedOrders.length === 0 ? (
                        <Card variant="outlined" className="text-center" style={{ padding: "40px", marginTop: '20px', marginBottom: '20px' }}>
                            <Typography variant="body2" color="textSecondary" component={'span'}  >
                                <strong>No items to display </strong>
                            </Typography>
                        </Card>
                    ) : (
                        <span
                            className="table-responsive"
                            style={{ paddingTop: "20px" }}
                        >
                            <table className="table table-hover table-center mb-0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Exporter</th>
                                        <th>Importer</th>
                                        <th>Documents by Importer</th>
                                        <th>Documents by Exporter</th>
                                        <th>Created At</th>
                                        <th>Upadated At</th>
                                        <th>Contract Time</th>
                                        <th style={{ textAlign: 'center', alignContent: 'center' }}>Price</th>
                                        <th>Admin Commission (%)</th>
                                        <th style={{ textAlign: 'center' }} >Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.fundsLockedOrders.map((i, index) => (
                                        <tr key={i._id}>
                                            <td>{index + 1}</td>
                                            <td>{i.exporterId.name}</td>
                                            <td>{i.importerId.name}</td>
                                            <td>
                                                {i.documentsByImporter.map((file, jindex) => {
                                                    return (
                                                        <p key={jindex}>
                                                            {file.name}{" "}
                                                            {"   "} <a
                                                                href={file.url}
                                                                download
                                                                target='_blank'
                                                                rel="noopener noreferrer"

                                                            >
                                                                <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                            </a>
                                                        </p>
                                                    );
                                                })}
                                            </td>
                                            <td>
                                                {i.documentsByExporter.map((file, jindex) => {
                                                    return (
                                                        <p key={jindex}>
                                                            {file.name}{" "}
                                                            {"   "} <a
                                                                href={file.url}
                                                                download
                                                                target='_blank'
                                                                rel="noopener noreferrer"

                                                            >
                                                                <i style={{ color: '#0dd9f9' }} className="fa fa-download"></i>
                                                            </a>
                                                        </p>
                                                    );
                                                })}
                                            </td>
                                            <td>
                                                <p><strong>Date: </strong>
                                                    {i.createdAt
                                                        ? new Date(i.createdAt).toLocaleDateString()
                                                        : ""}
                                                </p>
                                                <p>
                                                    <strong>Time: </strong>
                                                    {i.createdAt
                                                        ? new Date(i.createdAt).toLocaleTimeString()
                                                        : ""}
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <strong>Date: </strong>
                                                    {i.updatedAt
                                                        ? new Date(i.updatedAt).toLocaleDateString()
                                                        : ""}
                                                </p>
                                                <p>
                                                    <strong>Time: </strong>
                                                    {i.updatedAt
                                                        ? new Date(i.updatedAt).toLocaleTimeString()
                                                        : ""}
                                                </p>

                                            </td>
                                            <td>
                                                <p><strong>Date: </strong>
                                                    {i.orderTiming
                                                        ? new Date(i.orderTiming).toLocaleDateString()
                                                        : ""}
                                                </p>
                                                <p>
                                                    <strong>Time: </strong>
                                                    {i.orderTiming
                                                        ? new Date(i.orderTiming).toLocaleTimeString()
                                                        : ""}
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <strong>Admin Commission: </strong>{i.adminCommission / 10 ** 18} USDT
                                                </p>
                                                <p>
                                                    <strong>Contract Price: </strong>{i.numberOfTokens / 10 ** 18} USDT
                                                </p>
                                                <p>
                                                    <strong>Total Payable: </strong>{i.adminCommission / 10 ** 18 + i.numberOfTokens / 10 ** 18} USDT
                                                </p>
                                            </td>
                                            <td style={{ textAlign: 'center', alignContent: 'center' }}>{i.adminCommissionPercentage}%</td>
                                            <td>
                                                {" "}
                                                {
                                                    <span className="actions">
                                                        <Button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                props.setOrder(i);
                                                                props.setShow(true);
                                                            }}
                                                            className="btn btn-sm btn-block bg-warning-light"
                                                        >

                                                            view details
                                                        </Button>
                                                    </span>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </span>
                    )
                ) : (null)}
            </TabPanel>
        </div >
    );
}
