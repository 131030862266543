import {
  Card,
  CardContent, Grid
} from '@material-ui/core/';
// import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import DateTimePicker from 'react-datetime-picker';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  badge: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  card: {
    minWidth: 250,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));



function NewOrder(props) {
  const [inputList, setInputList] = useState([]);

  const classes = useStyles();
  let [isSaving, setIsSaving] = useState(false);
  let [category, setCategory] = useState('');
  let [description, setDescription] = useState('');
  let [fileData, setFileData] = useState('');
  let [isUploadingFileData, setIsUploadingFileData] = useState(false);

  let [tokens, setTokens] = useState();
  let [exporters, setExporters] = useState([]);
  let [exporterName, setExporterName] = useState('');
  let [exporterId, setExporterId] = useState();
  const [value, setValue] = useState(new Date());
  const { enqueueSnackbar } = useSnackbar();
  let [shipping, setShipping] = useState("noFreeShipping");

  let fileSelectHandler = (event, index) => {
    if (event.target.files[0] !== undefined) {
      setIsUploadingFileData(true);
      let selectedFile = event.target.files[0]
      let fileData = new FormData();
      fileData.append("image", selectedFile);
      axios.post("upload/uploadtos3", fileData).then(
        (response) => {
          console.log("response", response);
          setFileData(response.data.url);
          setIsUploadingFileData(false);
          let variant = "success";
          enqueueSnackbar('File Uploaded to S3 Successfully', { variant });
        },
        (error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(error);
            console.log(error.response);
          }
          setIsUploadingFileData(false);
          let variant = "error";
          enqueueSnackbar('Unable to Upload File to S3 .', { variant });
        }
      );
    }
  };


  let getExporters = () => {
    axios
      .get("/api/v1/importer/getAllExporters",)
      .then((response) => {
        console.log("response", response);
        setExporters(response.data.exporters);
      })
      .catch((error) => {
        setExporters([]);
        console.log(error);
        console.log(error.response);
      });
  };

  useEffect(() => {
    props.setActiveTab({
      dashboard: "",
      newOrders: "active",
      orders: "",
      ordersReceived: "",
      privacyPolicy: "",
      termsandconditions: "",
      settings: "",
      changePassword: "",
    });
    getExporters();// eslint-disable-next-line
  }, []);



  const handleSubmitEvent = (event) => {
    event.preventDefault();
    setIsSaving(true);

    let jwt = Cookies.get("Authorization");
    let jwtDecoded = jwtDecode(jwt);
    let importer = jwtDecoded.id;
    let exporter = exporterId;

    let catagoryArray = [];
    let descriptionArray = [];
    let filesArray = [];
    for (let i = 0; i < inputList.length; i++) {
      catagoryArray.push(inputList[i].category);
      descriptionArray.push(inputList[i].description);
      filesArray.push(inputList[i].fileData);
    }

    let orderData = {
      importerId: importer,
      exporterId: exporter,
      orderTiming: value.toString(),
      numberOfTokens: (tokens * 10 ** 18).toLocaleString('fullwide', {useGrouping:false}) ,
      freeShipping: shipping,
      description: descriptionArray,
      documentNames: catagoryArray,
      file: filesArray,
    }
    console.log("orderData", orderData);


    axios.post("api/v1/importer/addOrder", orderData).then(
      (response) => {
        setIsSaving(false);
        setInputList([]);
        let variant = "success";
        enqueueSnackbar('Order Added Successfully.', { variant });
      },
      (error) => {
        if (process.env.NODE_ENV === "development") {
          console.log(error);
          console.log(error.response);
        }
        setIsSaving(false);
        let variant = "error";
        enqueueSnackbar('Unable to Add Order.', { variant });
      }
    );
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = (e) => {
    e.preventDefault();
    setInputList([...inputList, { category: category, description: description, fileData: fileData }]);
    setDescription("");
    setCategory("");
  };

  return (
    <div className="card">
      <ul className="breadcrumb" style={{ backgroundColor: "#174153" }}>
        <li className="breadcrumb-item">
          <a href="/">Dashboard</a>
        </li>
        <li className="breadcrumb-item active">New Order</li>
      </ul>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            {/* <!-- Change Password Form --> */}
            <form >
              {/* onSubmit={handleSubmitEvent}> */}
              <div className="form-group">
                <label>Expected Delivery Time</label>
                <DateTimePicker
                  className="form-control"
                  onChange={setValue}
                  value={value}
                />
              </div>
              <div className="form-group">

                <label>Select Exporter</label>
                <div className="filter-widget">
                  <Autocomplete
                    id="combo-dox-demo"
                    required
                    options={exporters}
                    getOptionLabel={(option) =>
                      option.name + ", " + option.city + ", " + option.country
                    }
                    onChange={(event, value) => {
                      if (value == null) setExporterId("");
                      else {
                        setExporterId(value._id);
                        setExporterName(value.name);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Exporter"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
                <div className="form-group">
                  <label>Sale Price (USDTs)</label>
                  <div className="filter-widget">
                    <input
                      type="number"
                      required
                      value={tokens}
                      placeholder="eg. 22 ,0.00001 or .1"
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value >= 0)
                          setTokens(e.target.value);
                        else
                          setTokens(0);
                      }}
                    />
                  </div>
                </div>


                <div className="form-group">
                  <label>Upload Document</label>{" "}
                </div>
                {/* {inputList.map((x, i) => { */}
                {/* return ( */}
                <div className="form-group">
                  <div className="form-group">
                    <input
                      type="text"
                      required
                      value={category}
                      placeholder="Enter File Category"
                      className="form-control"
                      onChange={(e) => {
                        setCategory(e.target.value)
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      type="text"
                      required
                      rows="4"
                      value={description}
                      placeholder="Enter File Description"
                      className="form-control"
                      onChange={(e) => {
                        setDescription(e.target.value)
                      }}
                    />
                  </div>
                  <div className="form-group">

                    <input
                      required
                      type="file"
                      name="sampleFile"
                      accept=".jpg,.png,.jpeg,.jfif,.pdf,.docx"
                      className="form-control"
                      onChange={(e) => fileSelectHandler(e)}
                    />

                  </div>
                  {category === '' || description === '' || fileData === '' || exporterId === "" || typeof exporterId === "undefined" ? (
                    isUploadingFileData ? (
                      <div className="text-center">
                        <Spinner
                          animation="border"
                          role="status"
                          style={{ color: "#174153" }}
                        >
                        </Spinner>
                      </div>
                    ) : (
                      <button
                        className="btn"
                        type="submit"
                        disabled
                      >
                        <i className="fa fa-plus"></i> Add Document
                      </button>
                    )
                  ) : (
                    isUploadingFileData ? (
                      <div className="text-center">
                        <Spinner
                          animation="border"
                          role="status"
                          style={{ color: "#174153" }}
                        >
                        </Spinner>
                      </div>
                    ) : (
                      <button
                        className="btn"
                        type="submit"
                        onClick={(e) => handleAddClick(e)}
                      >
                        <i className="fa fa-plus"></i> Add Document
                      </button>
                    ))}
                </div>


                <FormControl component="fieldset">
                  <label component="legend">Who will pay the Admin Fee?</label>
                  <RadioGroup row aria-label="position" name="position" defaultValue="top">
                    <FormControlLabel style={{ color: 'black' }} value="noFreeShipping" onChange={() => {
                      setShipping("noFreeShipping");
                    }} checked={shipping === 'noFreeShipping'} control={<Radio color="primary" />} label="You" />
                    <FormControlLabel style={{ color: 'black' }} value="freeShipping" onChange={() => {
                      setShipping("freeShipping")
                    }} checked={shipping === 'freeShipping'} control={<Radio color="primary" />} label="Exporter" />

                  </RadioGroup>
                </FormControl>

              </div>
            </form>

          </div>
          <div className="col-md-12 col-lg-6">
            {/* <!-- Change Password Form --> */}
            <form onSubmit={handleSubmitEvent}>
              <div className="form-group">
                <div >
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="flex-start"
                  >
                    {inputList.map((i, index) => (
                      <Grid item xs={12} sm={6} md={6} key={index}>
                        <Card style={{ height: "100%" }} variant="outlined">
                          <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              <strong>Exporter:</strong>{exporterName}
                            </Typography>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              <strong>File Category:</strong>{i.category}
                            </Typography>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              <strong>File Description:</strong>{i.description}
                            </Typography>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              <strong>Document:</strong> <a style={{ color: 'blue' }} href={i.fileData} download
                                target='_blank'
                                rel="noopener noreferrer"> {i.fileData}</a>
                            </Typography>
                          </CardContent>
                          <div >
                            <CardActions>

                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleRemoveClick(index);
                                }}
                                className="btn btn-sm bg-danger-light btn-block"

                              >
                                Remove Document
                              </Button>
                            </CardActions>
                          </div>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </div>
            </form>
          </div>

        </div>
        {isSaving ? (
          <div className="text-center">
            <Spinner
              animation="border"
              role="status"
              style={{ color: "#00d0f1" }}
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          inputList.length === 0 ? (
            <div className="submit-section">
              <button type="button" disabled className="btn submit-btn">
                Create Order
              </button>
            </div>
          ) : (
            <div className="submit-section">
              <button type="button" onClick={handleSubmitEvent} className="btn submit-btn">
                Create Order
              </button>
            </div>
          )

        )}
      </div>
    </div>
  );
}

export default NewOrder;
